import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceSelector,
  clearDevicesStatus,
  setRestartDevice
} from "../../../../../../../redux/reducers/device/deviceReducer";
import {
  FORBIDDEN_STATUS,
  jsonData,
  severityList,
} from "../../../../../../constants/constants";
import { Row, Col, Checkbox, Tooltip, Popover, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { DataDisplayed } from "../../../../eventLogs/eventHeader/index.styles";
import { AntdInfinityTable } from "../../../../../stateless/common/antdTable/antdInfininityTable";
import CustomFilter from "../../../../../stateless/common/customTableFilter";
import {
  deepCopy,
  getStatusTypeEllipse,
  getTableFilterData,
  handleEnterKeyPress,
  sortList,
} from "../../../../../../../utility/utils";
import { locationListSelector } from "../../../../../../../redux/reducers/location/locationListReducer";
import "./styles.less";
import { getDevicesStatus } from "../../../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../../../redux/reducers/user/userReducer";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { xIcon } from "../../../../../../images";

const DevicesList = (props: any) => {
  const { deviceId, checkedList, setCheckedList, isCopyModal } = props;
  const {
    configDevices,
    devicesHealth,
    deviceList,
    deviceStatusMap,
    devicesStatusFormState,
  } = useSelector(DeviceSelector);
  const { locations } = useSelector(locationListSelector);
  const { appUser } = useSelector(userStateSelector);

  const masterDeviceId = deviceId;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationFilter, setLocationFilter] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([] as any);
  const [tableData, setTableData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [selectAll, setSelectAll] = useState(false);
  const [appFilter, setAppFilter] = useState([]);
  const [toggle, setToggle] = useState<boolean>(false);

  const masterDevice = tableData[0];

  const addPrefix = (arr: any[]) => {
    return arr.map((obj) => {
      let prefix = isCopyModal ? "copy-" : "save-";
      let newObj = {};
      for (let key in obj) {
        newObj[prefix + key] = obj[key];
      }
      return newObj;
    });
  };

  const removePrefix = (arr) => {
    return arr.map((obj) => {
      let newObj = {};
      for (let key in obj) {
        let newKey = key.replace(/^copy-|^save-/, "");
        newObj[newKey] = obj[key];
      }
      return newObj;
    });
  };

  const onSelect = (event: any, checked: boolean, DeviceId: any) => {
    if (event.key === "Enter") {
      return event.target.click();
    }

    if (!checked) {
      setCheckedList(
        checkedList?.filter(
          (item: any) => item?.toString() !== DeviceId?.toString()
        )
      );
    } else {
      setCheckedList([...checkedList, DeviceId]);
    }
  };

  const getSelectableList = () => {
    let list = [...tableData].slice(1);

    let selectableList = list
      .filter((item: any) => !isSelectionDisabled(item).isDisabled)
      .map((item: any) => item.DeviceId);

    return selectableList;
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    if (selectAll) {
      setCheckedList(isCopyModal ? [] : [masterDeviceId]);
    } else {
      let list = getSelectableList();
      setCheckedList(isCopyModal ? [...list] : [masterDeviceId, ...list]);
    }
  };

  const replaceEmptyKey = (obj: any) => {
    const inputObject = obj;
    for (const key in inputObject) {
      if (Array.isArray(inputObject[key]) && inputObject[key].length === 0) {
        inputObject[key] = null;
      }
    }
    return inputObject;
  };

  function haveCommonElement(arr1, arr2) {
    const set = new Set(arr1);
    for (const elem of arr2) {
      if (set.has(elem)) {
        return true;
      }
    }
    return false;
  }

  const applyFilter = (filters: any) => {
    let copyFilters = deepCopy(filters);

    let keyName = "";
    let appVersionFilters = [];

    if (copyFilters.hasOwnProperty("copy-AppVersions")) {
      keyName = "copy-AppVersions";
    } else if (copyFilters.hasOwnProperty("save-AppVersions")) {
      keyName = "save-AppVersions";
    }

    appVersionFilters = copyFilters[keyName];
    delete copyFilters[keyName];

    getTableFilterData(copyFilters, unfilteredData, unfilteredData)
      .then((tableFilterData: any) => {
        if (appVersionFilters) {
          const arr = tableFilterData.filter((element: any) => {
            return haveCommonElement(element[keyName], filters[keyName]);
          });
          setTableData(removePrefix(arr));
        } else {
          setTableData(removePrefix(tableFilterData));
        }
      })
      .catch((err) => {
        console.log("Failed to get Filtered data ", err);
      });
  };

  const selectedDeviceStateUpdate = (data: any, key: any) => {
    let updatedDeviceStates = { ...filteredInfo };
    if (updatedDeviceStates?.hasOwnProperty(key)) {
      updatedDeviceStates = { ...updatedDeviceStates, [`${key}`]: data[key] };
    } else {
      updatedDeviceStates = { ...updatedDeviceStates, ...data };
    }

    const filters = replaceEmptyKey(updatedDeviceStates);
    applyFilter(filters);
    setFilteredInfo(filters);
  };

  function moveMasterToFirst(data: any[]) {
    if (data.length > 0) {
      data?.sort((a, b) => a?.DeviceName.localeCompare(b?.DeviceName));
      const index = data?.findIndex((obj) => obj.DeviceId === masterDeviceId);
      if (index !== -1 && index !== 0) {
        const objectToMove = data.splice(index, 1)[0];
        data?.unshift(objectToMove);
      }
    }
    return data;
  }

  const checkVersionMismatch = (device: any) => {
    let isMajorDifference = false;

    const appToIgnore = "smartadmin client";
    const currentDeviceApps = device.Applications;
    const masterDeviceApps = masterDevice.Applications;

    let result = currentDeviceApps.map((currentApp: any) => {
      if (currentApp.Name === appToIgnore) {
        return null;
      }

      let masterApp = masterDeviceApps.find(
        (item: any) =>
          item.Name?.toLowerCase() === currentApp.Name?.toLowerCase()
      );

      if (masterApp) {
        let currentMajorVersion = currentApp.Version.split(".")[0];
        let masterMajorVersion = masterApp.Version.split(".")[0];

        if (currentMajorVersion === masterMajorVersion) {
          return false;
        } else {
          return true;
        }
      }
    });

    if (result.includes(true)) {
      isMajorDifference = true;
    }

    return isMajorDifference;
  };

  const isSelectionDisabled = (device: any) => {
    const currentDeviceStatus = deviceStatusMap[device.DeviceId];

    const isMaster = device?.DeviceId === masterDeviceId;
    const isForbidden = FORBIDDEN_STATUS.includes(device?.Severity);
    const isMajorDifference = checkVersionMismatch(device);
    const isLockedByUser = currentDeviceStatus?.LockState.Locked;
    const isWorkItemProcessing = currentDeviceStatus?.ProcessingWorkItem;

    let warningText = "";

    if (isMaster) {
      warningText = isCopyModal
        ? t(jsonData.MasterDeviceCopyWarnMsg)
        : t(jsonData.MasterDeviceSaveWarnMsg);
    } else {
      if (isForbidden) {
        warningText = t(jsonData.ForbiddenStatusWarnMessage);
      }
      if (isMajorDifference) {
        warningText = warningText + " " + t(jsonData.SoftwareCheckMessage);
      }
      if (isLockedByUser) {
        warningText =
          warningText +
          " " +
          t(jsonData.DeviceLocked) +
          " " +
          currentDeviceStatus.LockState.LockedByUsername;
      }
      if (isWorkItemProcessing) {
        warningText = warningText + " " + t(jsonData.ConfigurationInProgress);
      }
    }

    let isDisabled =
      isMaster ||
      isForbidden ||
      isMajorDifference ||
      isLockedByUser ||
      isWorkItemProcessing;
    return {
      isDisabled: isDisabled,
      warningText: warningText,
    };
  };

  const renderAppVersions = (device: any) => {
    if (device?.Applications.length > 1) {
      return device?.Applications?.filter((item: any) => {
        if (item.Name !== "smartadmin client") {
          return item;
        }
      });
    } else {
      return device?.Applications?.map((item: any) => {
        return {
          Name: item.Name,
          Version: item.Version,
        };
      });
    }
  };

  useEffect(() => {
    if (configDevices?.length > 0) {
      const deviceIds = configDevices.map((item: any) => {
        return item.DeviceId;
      });

      dispatch(
        getDevicesStatus({
          userName: appUser?.userName,
          deviceIdList: deviceIds,
        })
      );
    }

    return () => {
      dispatch(clearDevicesStatus());
      setCheckedList(isCopyModal ? [] : [masterDeviceId]);
    };
  }, []);

  useEffect(() => {
    const getLocations = (list: any) => {
      const arrLocation = list?.map((item: any) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      const sortedBylabel = arrLocation.sort(sortList("text"));
      return sortedBylabel;
    };

    setLocationFilter(getLocations(locations));
  }, [locations]);

  useEffect(() => {
    const getDeviceList = (list: any) => {
      const arrDevices = list?.map((item: any) => {
        return {
          text: item.info.name ?? item.serialNo ?? item.id,
          value: item.info.name ?? item.serialNo ?? item.id,
        };
      });
      const sortedBylabel = arrDevices.sort(sortList("text"));
      return sortedBylabel;
    };

    setDeviceFilter(getDeviceList(deviceList));
  }, [deviceList]);

  useEffect(() => {
    function getSeverityByDeviceId(deviceId: string, allDevices: any) {
      const device = allDevices?.find(
        (device: any) => device.lcDeviceId === deviceId
      );
      if (device) {
        return device.statusType;
      }
      return null;
    }

    if (configDevices) {
      let allowedDevices = [];

      for (let i = 0; i < configDevices.length; i++) {
        let severity = getSeverityByDeviceId(
          configDevices[i].DeviceId,
          devicesHealth
        );

        if (severity) {
          allowedDevices.push({
            ...configDevices[i],
            Severity: severity,
          });
        }
      }

      allowedDevices = allowedDevices.map((currentDevice: any) => {
        return {
          ...currentDevice,
          AppVersions: renderAppVersions(currentDevice).map((item) => {
            return `${item.Name} - ${item.Version}`;
          }),
        };
      });

      const set = new Set();
      allowedDevices.map((device: any) => {
        device.Applications.map((currentApp: any) => {
          set.add(`${currentApp.Name} - ${currentApp.Version}`);
        });
      });

      let applicationFilter = [];
      for (const value of set) {
        applicationFilter.push({
          text: value,
          value: value,
        });
      }

      setAppFilter(applicationFilter);
      setTableData(allowedDevices);
      setUnfilteredData(addPrefix(allowedDevices));
    }
  }, [configDevices]);

  useEffect(() => {
    const selectableListLength = getSelectableList()?.length || 0;
    const isAllChecked =
      checkedList?.length >= 1 &&
      checkedList.length === selectableListLength + (isCopyModal ? 0 : 1);
    setSelectAll(isAllChecked);
  }, [checkedList, isCopyModal, tableData]);

  const configDeviceColumns = () => {
    let columns = [
      {
        title: (
          <div className="justify-center checkbox device-list-header-checkbox">
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              onKeyDown={(e: any) => handleEnterKeyPress(e, handleSelectAll)}
              disabled={getSelectableList()?.length === 0}
            />
          </div>
        ),
        ellipsis: true,
        render: (data: any) => {
          return (
            <div className="justify-center checkbox">
              <Tooltip
                mouseLeaveDelay={0}
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={isSelectionDisabled(data).warningText}
              >
                <Checkbox
                  checked={checkedList.includes(data.DeviceId)}
                  onChange={(e) =>
                    onSelect(
                      e,
                      !checkedList.includes(data.DeviceId),
                      data.DeviceId
                    )
                  }
                  onKeyDown={(e) =>
                    handleEnterKeyPress(e, () =>
                      onSelect(
                        e,
                        !checkedList.includes(data.DeviceId),
                        data.DeviceId
                      )
                    )
                  }
                  disabled={isSelectionDisabled(data).isDisabled}
                />
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: (
          <Tooltip
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.Status)}
          >
            <div className="headText" onClick={(e) => e.stopPropagation()}>
              <p className="mb-0"> {t(jsonData.Status)}</p>
              <CustomFilter
                optionList={severityList}
                selectedData={selectedDeviceStateUpdate}
                selectedItems={
                  filteredInfo?.[`${isCopyModal ? "copy" : "save"}-Severity`] ??
                  []
                }
                allSelectedText={t(jsonData.AllStates)}
                placeholder={t(jsonData.SelectState)}
                optionListClass={`${
                  isCopyModal ? "copy" : "save"
                }-Severity-optionList`}
                wrapperClassName="state-multiselect"
                coulmnName={`${isCopyModal ? "copy" : "save"}-Severity`}
                filterName={t(jsonData.Severity)}
              />
            </div>
          </Tooltip>
        ),
        key: "Severity",
        dataIndex: "Severity",
        ellipsis: true,
        render: (severity: string) => {
          return (
            <span>
              <img
                src={getStatusTypeEllipse(severity?.toString().toLowerCase())}
                alt=""
              />
              &nbsp;{severity}
            </span>
          );
        },
      },
      {
        title: (
          <Tooltip
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.Location)}
          >
            <div className=" headText" onClick={(e) => e.stopPropagation()}>
              <p className="mb-0">{t(jsonData.Location)}</p>

              <CustomFilter
                optionList={locationFilter}
                selectedData={selectedDeviceStateUpdate}
                selectedItems={
                  filteredInfo?.[
                    `${isCopyModal ? "copy" : "save"}-LocationFullName`
                  ] ?? []
                }
                allSelectedText={t(jsonData.AllStates)}
                placeholder={t(jsonData.SelectState)}
                optionListClass={`${
                  isCopyModal ? "copy" : "save"
                }-location-optionList`}
                wrapperClassName="state-multiselect"
                coulmnName={`${isCopyModal ? "copy" : "save"}-LocationFullName`}
                filterName={t(jsonData.locationFilter)}
                allowSearch={true}
              />
            </div>
          </Tooltip>
        ),
        key: "LocationFullName",
        dataIndex: "LocationFullName",
        ellipsis: true,
      },
      {
        title: (
          <Tooltip
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.DeviceName)}
          >
            <div className=" headText" onClick={(e) => e.stopPropagation()}>
              <p className="mb-0">{t(jsonData.DeviceName)}</p>
              <CustomFilter
                optionList={deviceFilter}
                selectedData={selectedDeviceStateUpdate}
                selectedItems={
                  filteredInfo?.[
                    `${isCopyModal ? "copy" : "save"}-DeviceName`
                  ] ?? []
                }
                allSelectedText={t(jsonData.AllStates)}
                placeholder={t(jsonData.SelectState)}
                optionListClass={`${
                  isCopyModal ? "copy" : "save"
                }-device-name-optionList`}
                wrapperClassName="state-multiselect"
                coulmnName={`${isCopyModal ? "copy" : "save"}-DeviceName`}
                filterName={t(jsonData.deviceNameFilter)}
                allowSearch={true}
              />
            </div>
          </Tooltip>
        ),
        key: "DeviceName",
        ellipsis: true,
        render(data) {
          return (
            <div className="deviceName-column">
              <p className="mb-0 mr-8">{data.DeviceName}</p>
              <Popover
                trigger={["click", "focus"]}
                placement="rightBottom"
                content={
                  <div className="device-info-popover">
                    <div className="propery-node-id ant-input ant-input-disabled">
                      <Tooltip
                        mouseLeaveDelay={0}
                        overlayClassName="dashBoardTooltip"
                        placement="bottom"
                        title={t(jsonData.NodeId)}
                      >
                        <p>{t(jsonData.ProductsInstalledAre)}</p>
                        {data.Applications.map((application: any) => {
                          return (
                            <p className="mb-0">
                              {application.Name} - {application.Version}
                            </p>
                          );
                        })}
                      </Tooltip>
                    </div>
                    <span className="node-id-copy-button"></span>
                  </div>
                }
              >
                <InfoCircleOutlined className="device-list-info" tabIndex={0} />
              </Popover>
            </div>
          );
        },
      },
      {
        title: (
          <Tooltip
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.Application)}
          >
            <div className=" headText" onClick={(e) => e.stopPropagation()}>
              <p className="mb-0">{t(jsonData.Application)}</p>

              <CustomFilter
                optionList={appFilter}
                selectedData={selectedDeviceStateUpdate}
                selectedItems={
                  filteredInfo?.[
                    `${isCopyModal ? "copy" : "save"}-AppVersions`
                  ] ?? []
                }
                allSelectedText={t(jsonData.AllStates)}
                placeholder={t(jsonData.SelectState)}
                optionListClass={`${
                  isCopyModal ? "copy" : "save"
                }-AppVersions-optionList`}
                wrapperClassName="state-multiselect"
                coulmnName={`${isCopyModal ? "copy" : "save"}-AppVersions`}
                filterName={t(jsonData.AppVersions)}
              />
            </div>
          </Tooltip>
        ),
        key: "Application",
        ellipsis: true,
        render: (data: any) => {
          return (
            <div className="multiline-text pl-0">
              {data?.AppVersions?.map((item: string) => {
                return (
                  <div>
                    {checkVersionMismatch(data) ? (
                      <img src={xIcon} alt="" />
                    ) : (
                      <CheckOutlined className="color-green" />
                    )}{" "}
                    {item}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        key: `${t(jsonData.Note)}`,
        ellipsis: true,
        title: `${t(jsonData.Note)}`,
        render: (data: any) => {
          return (
            <div className="multiline-text">
              {isSelectionDisabled(data).warningText}
            </div>
          );
        },
      },
    ];

    return columns;
  };
  
  const handleToggle = (value: any) => {
    setToggle(value)
    dispatch(setRestartDevice(value))
  };

  return (
    <div className="device-list-container">
      <Row className="device-popup-header">
        <Col className="device-popup-heading">
          <div>
            {isCopyModal
              ? t(jsonData.CopyToDevices)
              : t(jsonData.StoreToDevices)}
          </div>
        </Col>
        <Col span={24} >
          <div className="sub-header-content">
            <DataDisplayed>
              {t(jsonData.NumberOfDevices)}: {tableData?.length}
            </DataDisplayed>
            <div className="app-restart-toggle">
              {t(jsonData.Restart)}: {
                <Switch
                  className={`${toggle ? "scheduleToggleCheck" : "scheduleToggleOff"} ScheduleToggleSwitch`}
                  checked={toggle}
                  onChange={handleToggle}
                />
              }
            </div>
          </div>
        </Col>
      </Row>
      <AntdInfinityTable
        tableClassName="device-config-table"
        dataSource={
          devicesStatusFormState?.loading ? [] : moveMasterToFirst(tableData)
        }
        scroll={{
          y: 250,
          scrollToFirstRowOnChange: true,
        }}
        locale={!tableData?.length && { emptyText: t(jsonData.NoDataFound) }}
        columns={configDeviceColumns()}
        pagination={false}
      />
      {devicesStatusFormState?.loading && (
        <div className="loaderWrapper">
          <div className="loaderBody">
            <span className="spinner"></span>
            <span className="loadingText">{t(jsonData.Loading)}.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DevicesList;
