/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import {
  StyledChartdiv,
  TooltipXDIv,
  TooltipYDiv,
  TooltipMainDiv,
  GraphHeading,
  InsightLineGraphRow,
  Legends,
  LegendsItem,
  ColorDot,
} from "../insights.styles";
import { Dropdown, Button } from "antd";
import { DownOutlined, RightCircleFilled } from "@ant-design/icons";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment-timezone";
import { insightsSelector } from "../../../../../redux/reducers/insights/insightsReducer";
import {
  colorCodes,
  formatScaling,
  getColorScheme,
} from "../../../../../utility/insightUtils";
import { RightCircledStyle } from "./index.styles";
import {
  getFormattedDate,
  getOrganizationDateAndTime,
} from "../../../../../utility/appUtil";
import {
  DurationDropDownConstants,
  FrequencyDropdown,
  GroupBy,
} from "../../../../constants/constants";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";

export default (props: any) => {
  const { t } = useTranslation();
  const {
    graphType,
    graphData,
    format,
    menu,
    groupBy,
    index,
    groupByValue,
    lineGraphData,
    startPage,
    endPage,
    colorKeys,
  } = props;
  const { reportData, reportValues } = useSelector(insightsSelector);
  const [yMax, setYMax] = useState<number | "auto">("auto");
  const [yScale, setYScale] = useState<number[]>();
  const [screenWidth, setScreenWidth] = useState(document.body?.clientWidth);
  const [groupedData,setGroupedData] = useState<any>([]);
  const [isGroupByValue, setIsGroupByValue] = useState<boolean>(false)

  let chartData = graphData.data;
  const sorter = {
    // "sunday": 0, // << if sunday is first day of week
    "Mon": 1,
    "Tue": 2,
    "Wed": 3,
    "Thu": 4,
    "Fri": 5,
    "Sat": 6,
    "Sun": 7
  }
  
  //let groupedData = []

  const formatNumber = (num) => {
    let localeInfo:any = secureLocalStorage.getItem('locale');

  switch (localeInfo?.culture) {
      case 'en-US':
        return new Intl.NumberFormat('en-US').format(num);
      case 'de':
      case 'de-DE':
        return new Intl.NumberFormat('de-DE').format(num);
      case 'da':
      case 'da-DA':
        return new Intl.NumberFormat('da-DA').format(num).replace(/\./g, ' ').replace(/ (\d{3})$/, ' $1'); 
      case 'da':
      case 'da-DK': 
        return new Intl.NumberFormat('da-DK').format(num).replace(/\./g, ' ').replace(/ (\d{3})$/, ' $1');
      case 'es' :
      case 'es-ES':
        return new Intl.NumberFormat('es-ES').format(num);
      case 'fr':
      case 'fr-FR':
        return new Intl.NumberFormat('fr-FR').format(num);        
      case 'fr-CA':
        return new Intl.NumberFormat('fr-CA').format(num);
      default:
        return new Intl.NumberFormat('en-US').format(num);
  }
}
  useEffect(() => {
    // check the heighest value for y axis
    let ceil =
      graphData
        ? Math.ceil(
          chartData.map(({ data }) => data
            ?.map((item) => item.y)
            .sort((a, b) => a - b)
            .pop()).sort((a, b) => a - b).pop() / 15
        )
        : 1
    ceil = ceil || 1
    let max = ceil * 15
    setYScale([0, 1 * 5 * ceil, 2 * 5 * ceil, 3 * 5 * ceil])
    setYMax(max)
  }, [graphData])


  useEffect(() => {
    const node = document.body
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenWidth(node?.clientWidth)
    });
    resizeObserver.observe(node)
  }, [])

  useEffect(() => {
    if(groupByValue && groupBy == GroupBy?.DAY && reportData?.interval == FrequencyDropdown?.DAY_VALUE){
      for(let datum of chartData){
        let arr = []
        for(let i of datum?.data){
          arr.push({
            x: i.x?.includes(":") ? moment(i.x).format('ddd') : i.x,
            y: i.y
          })
        }
        arr.sort(function sortByDay(a, b) {
          let day1 = a.x;
          let day2 = b.x;
          return sorter[day1] - sorter[day2];
        });
        datum.data = arr
      }
      setIsGroupByValue(true)
      setGroupedData(chartData)
   }
  }, [groupByValue,chartData,groupBy,reportData?.interval])

  const scheme=[...colorCodes]
  // const colorScheme = {
  //   [t("Successful")]:scheme[1],
  //   [t("Failed")]:scheme[0],
  //   [t("Success")]:scheme[1],
  //   [t("People In")] : scheme[2],
  //   [t("People Out")]:scheme[1],
  //   [t("Detections")]:scheme[0],
  //   [t("Actions")]:scheme[0],
  //   [t("Hold")]:scheme[0],
  //   [t("Email")]:scheme[1],
  //   [t("Print")]:scheme[2],
  //   [t("Checkout")]:scheme[3],
  //   [t("Select")]:scheme[4],
  //   [t("Session receipt")]:scheme[5],
  //   [t("Display")]:scheme[0],
  //   [t("Amount Entered")]:scheme[0]
  // }

  const colorScheme = {
    ["Successful"]: scheme[1],
    ["Failed"]: scheme[0],
    ["Success"]: scheme[1],
    ["People In"]: scheme[2],
    ["People Out"]: scheme[1],
    ["Detections"]: scheme[0],
    ["Actions"]: scheme[0],
    ["Hold"]: scheme[0],
    ["Email"]: scheme[1],
    ["Print"]: scheme[2],
    ["Checkout"]: scheme[3],
    ["Select"]: scheme[4],
    ["Session receipt"]: scheme[5],
    ["Display"]: scheme[0],
    ["Amount Entered"]: scheme[0],
  };

  const getColor = (datum) => {
    var bySorting = getColorScheme(colorKeys, scheme);
    if (bySorting) {
      let color = bySorting[`${datum.id}`] || "red";
      return color;
    } else {
      let color = colorScheme[`${datum.id}`] || "red";
      return color;
    }
  };

  const dividePoints = (points: any) => {
    let newArray = [];
    let count = 0;
    let temp = [];
    for (let i = 0; i < points.length; i++) {
      temp.push(points[i]);
      count++;
      if (count === 4) {
        newArray.push(temp);
        temp = [];
        count = 0;
      }
    }
    return newArray;
  };

  var returnValue;

  let showMarker = () => {
    let currentDate = getOrganizationDateAndTime(new Date().toISOString());
    let currentDateValue = new Date(currentDate).getTime();
    let startDate = new Date(startPage).getTime();
    let endDate = new Date(endPage).getTime();
    if (groupByValue) {
      return false;
    }
    if (currentDateValue >= startDate && currentDateValue <= endDate) {
      return true;
    }
    return false;
  };

  const getDate = getOrganizationDateAndTime(new Date().toISOString());
  let getCurrentDayTime = () => {
    let date = new Date(getDate);
    if (
      reportValues?.filterDuration === DurationDropDownConstants.THIS_WEEK_VALUE
    ) {
      if (reportData?.interval === FrequencyDropdown.DAY_VALUE) {
        date.setHours(0, 0, 0);
      } else if (reportData?.interval === FrequencyDropdown.HOUR_VALUE) {
        date.setHours(date.getHours(), 0, 0);
      }
    } else if (
      reportValues?.filterDuration ===
      DurationDropDownConstants.THIS_MONTH_VALUE
    ) {
      if (reportData?.interval === FrequencyDropdown.DAY_VALUE) {
        date.setHours(0, 0, 0);
      } else if (reportData?.interval === FrequencyDropdown.HOUR_VALUE) {
        date.setHours(date.getHours(), 0, 0);
      }
    } else if (
      reportValues?.filterDuration === DurationDropDownConstants.THIS_YEAR_VALUE
    ) {
      if (reportData?.interval === FrequencyDropdown.MONTH_VALUE) {
        date.setHours(0, 0, 0);
        date.setDate(1);
      } else if (reportData?.interval === FrequencyDropdown.DAY_VALUE) {
        date.setHours(0, 0, 0);
      } else if (reportData?.interval === FrequencyDropdown.HOUR_VALUE) {
        date.setHours(date.getHours(), 0, 0);
      }
    } else if (
      reportValues?.filterDuration === DurationDropDownConstants.TODAY_VALUE
    ) {
      date.setHours(date.getHours(), 0, 0);
    } else if (
      reportValues?.filterDuration === DurationDropDownConstants.CUSTOM_VALUE
    ) {
      if (reportData?.interval === FrequencyDropdown.MONTH_VALUE) {
        date.setHours(0, 0, 0);
        date.setDate(1);
      } else if (reportData?.interval === FrequencyDropdown.DAY_VALUE) {
        date.setHours(0, 0, 0);
      } else if (reportData?.interval === FrequencyDropdown.HOUR_VALUE) {
        date.setHours(date.getHours(), 0, 0);
      }
    }

    return date;
  };

  return (
    <>
      <Col
        key={index}
        className="graph-card"
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={lineGraphData.length !== 1 ? 12 : 24}
        xxl={lineGraphData.length !== 1 ? 12 : 24}
      >
        <StyledChartdiv screen={"lineGraph"} className="insightGraph" id={`graph-${index}`} style={{ height: graphType ? "45vh" : "" }}>
          <InsightLineGraphRow>
          <Col>
            <GraphHeading>
              {graphData.header.split(":").map((part, index, array) => {
              const partColon = index < array.length - 1 ? part + " : " : part;
                return (
                <span key={index} className={index === 1 ? 'dateHeader' : ''}> {partColon} </span>
              );
            })}
            </GraphHeading> 
          </Col>
            {graphData?.header?.includes("Footfall") ?
              
              <Col className="graphNumber">
                <> {t('Total')}: </>
                <>
                {t('In')}
                <b className='peopleInInsight'>
                  { formatNumber( chartData?.reduce((acc, c) => {                  
                    if (c.id == "People In") {
                      returnValue = c.total
                    }
                  }, 0) || returnValue)}
                </b>    
                </>
                <>  
                { chartData?.id == "People Out"}                    
                {t("Out")}
                <b>
                  {formatNumber(chartData?.reduce((acc, c) => {
                    if (c.id == "People Out")
                    {
                      returnValue = c.total
                    }
                  }, 0) || returnValue )}
                </b>
                </>
                
              </Col> :
              <Col className="graphNumber">
                {t('Total')}:
                <b>{chartData[0]?.currency}
                  {formatNumber(chartData?.reduce((acc, c) => {
                    return acc + c.total
                  }, 0) || 0 )}
                </b>
              </Col>
          
          }
            
          </InsightLineGraphRow>

          <div className="graphSubHead">
            {/* Show{' '} */}
            {/* <span className='graphSubHeadSpan'>
              <Dropdown overlay={menu} disabled={true}>
                <Button className='insightLineGraph'
                  shape="round"
                >
                  {groupBy} <DownOutlined className='graphDownOutlined' />
                </Button>
              </Dropdown>{' '}
            </span> */}
          </div>
          {isGroupByValue ? 
              <ResponsiveLine
              data={groupedData}
              margin={{ top: 10, right: 120, bottom: 100, left: 50 }}
              xScale={{
                type: "point",
              }}
              // yFormat={format?.y?.format ?? ''}
              // xFormat={format?.x?.format ?? "time:%A"}
              yScale={{
                type: "linear",
                stacked: false,
                max: yMax || "auto",
              }}
              axisLeft={{
                legend: "",
                legendOffset: -32,
                legendPosition: "end",
                tickValues: yScale || 4,
                tickSize: 0,
                tickPadding: 15,
                format: function (num) {
                  return formatScaling(num);
                },
              }}
              gridYValues={4}
              enableGridX={false}
              axisBottom={{                                                                             
                tickSize: 0,
                tickPadding: 20,
                ticksPosition: "after",
              }}
             
              curve={"monotoneX"}
              enableCrosshair={true}
              enableSlices={"x"}
              colors={(datum) => getColor(datum)}
              pointSize={2}
              pointColor={{ theme: "background" }}
              pointBorderWidth={3}
              pointBorderColor={{ from: "serieColor" }}
              useMesh={false}
              legends={
                graphType
                  ? []
                  : [
                      {
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 100,
                        translateY: 10,
                        itemsSpacing: 0,
                        itemDirection: "left-to-right",
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: "circle",
                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                      },
                    ]
              }
              sliceTooltip={({ slice }) => {
                let points = graphType
                  ? slice?.points.slice()?.reverse()
                  : slice?.points;
  
                let dividedPoints: any = dividePoints(points);
                return (
                  <TooltipMainDiv>
                    <TooltipXDIv>
                      {groupByValue && reportData?.interval === "day"
                        ? moment
                            .utc(points[0].data.x)
                            .utcOffset(
                              -new Date(points[0].data.x).getTimezoneOffset()
                            )
                            .format("dddd")
                        : points[0].data.xFormatted}
                    </TooltipXDIv>
                    <Row style={{width: !graphType ? "100%" : ""}}>
                      {Array.from(
                        { length: Math.ceil(points.length / 5) },
                        (_, colIndex) => (
                          <Col  style={{width: !graphType ? "100%" : "", marginRight: graphType ? "10px" : "" }} key={colIndex} >
                            {points
                              ?.slice(colIndex * 10, (colIndex + 1) * 10)
                              .map((_, index, arr) => {
                                let point = arr[arr.length - 1 - index];
                                return (
                                  <Col>
                                    <div className="InsightGrapghTooltip" style={{height: graphType ? "20px" : ""}}>
                                      <RightCircledStyle
                                        color={point.serieColor}
                                      />
                                      <TooltipXDIv className="InsightTooltipXDiv">
                                        {point.serieId}
                                      </TooltipXDIv>
                                      <TooltipYDiv className="InsightTooltipYDiv">
                                        {(chartData[0]?.currency ?? "") +
                                          formatNumber(point.data.y)}
                                      </TooltipYDiv>
                                    </div>
                                  </Col>
                                );
                              })}
                          </Col>
                        )
                      )}
                    </Row>
                    <TooltipXDIv className="graphHeaderColor">
                      {graphData.header}
                    </TooltipXDIv>
                  </TooltipMainDiv>
                );
              }}
            ></ResponsiveLine> 
             :
            <ResponsiveLine
            data={chartData}
            margin={{ top: 10, right: 120, bottom: 100, left: 50 }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d %H:%M",
              min: "auto",
              max: "auto",
              useUTC: false,
              precision: format?.x?.scale?.precision ?? "day",
            }}
            // yFormat={format?.y?.format ?? ''}
            xFormat={format?.x?.format ?? "time:%A"}
            yScale={{
              type: "linear",
              stacked: false,
              max: yMax || "auto",
            }}
            axisLeft={{
              legend: "",
              legendOffset: -32,
              legendPosition: "end",
              tickValues: yScale || 4,
              tickSize: 0,
              tickPadding: 15,
              format: function (num) {
                return formatScaling(num);
              },
            }}
            gridYValues={4}
            enableGridX={false}
            axisBottom={{
              format:
                (format?.x?.scale?.tickValue == "every day" &&
                  (screenWidth > 1199 || screenWidth <= 900) &&
                  lineGraphData.length != 1) ||
                (screenWidth <= 900 &&
                  lineGraphData.length == 1 &&
                  format?.x?.scale?.tickValue == "every day")
                  ? "%a"
                  : format?.x?.scale?.format ?? "%A",
              tickValues: format?.x?.scale?.tickValue ?? "every day",
              tickSize: 0,
              tickPadding: 20,
              ticksPosition: "after",
            }}
            markers={[
              showMarker()
                ? {
                    axis: "x",
                    value: getCurrentDayTime(),
                    lineStyle: {
                      stroke: "#cbcbcb",
                      strokeWidth: 1,
                      strokeDasharray: 5,
                    },
                    textStyle: {
                      fill: "#000000",
                      fontSize: 12,
                    },
                  }
                : {
                    axis: "y",
                    value: 0,
                  },
            ]}
            curve={"monotoneX"}
            enableCrosshair={true}
            enableSlices={"x"}
            colors={(datum) => getColor(datum)}
            pointSize={2}
            pointColor={{ theme: "background" }}
            pointBorderWidth={3}
            pointBorderColor={{ from: "serieColor" }}
            useMesh={false}
            legends={
              graphType
                ? []
                : [
                    {
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 100,
                      translateY: 10,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                    },
                  ]
            }
            sliceTooltip={({ slice }) => {
              let points = graphType
                ? slice?.points.slice()?.reverse()
                : slice?.points;

              let dividedPoints: any = dividePoints(points);
              return (
                <TooltipMainDiv>
                  <TooltipXDIv>
                    {groupByValue && reportData?.interval === "day"
                      ? moment
                          .utc(points[0].data.x)
                          .utcOffset(
                            -new Date(points[0].data.x).getTimezoneOffset()
                          )
                          .format("dddd")
                      : points[0].data.xFormatted}
                  </TooltipXDIv>
                  <Row style={{width: !graphType ? "100%" : ""}}>
                    {Array.from(
                      { length: Math.ceil(points.length / 5) },
                      (_, colIndex) => (
                        <Col  style={{width: !graphType ? "100%" : "", marginRight: graphType ? "10px" : "" }} key={colIndex} >
                          {points
                            ?.slice(colIndex * 10, (colIndex + 1) * 10)
                            .map((_, index, arr) => {
                              let point = arr[arr.length - 1 - index];
                              return (
                                <Col>
                                  <div className="InsightGrapghTooltip" style={{height: graphType ? "20px" : ""}}>
                                    <RightCircledStyle
                                      color={point.serieColor}
                                    />
                                    <TooltipXDIv className="InsightTooltipXDiv">
                                      {point.serieId}
                                    </TooltipXDIv>
                                    <TooltipYDiv className="InsightTooltipYDiv">
                                      {(chartData[0]?.currency ?? "") +
                                        formatNumber(point.data.y)} 
                                    </TooltipYDiv>
                                  </div>
                                </Col>
                              );
                            })}
                        </Col>
                      )
                    )}
                  </Row>
                  <TooltipXDIv className="graphHeaderColor">
                    {graphData.header}
                  </TooltipXDIv>
                </TooltipMainDiv>
              );
            }}
            ></ResponsiveLine>
          }
          {graphType && (
            <Legends className="scrollStyle">
              {chartData.map((item, index: number) => {
                return (
                  <LegendsItem>
                    <ColorDot
                      style={{
                        backgroundColor: colorCodes[index],
                      }}
                    ></ColorDot>
                    <span style={{ paddingLeft: "8px" }}>{item.id}</span>
                  </LegendsItem>
                );
              })}
            </Legends>
          )}
        </StyledChartdiv>
      </Col>
    </>
  );
};
function getReportList() {
  throw new Error("Function not implemented.");
}

