/* eslint-disable array-callback-return */
import { Workbook, Border } from 'exceljs'
import { saveAs } from 'file-saver'
import moment from "moment-timezone"
import { FrequencyDropdown, TimePattern } from '../web/constants/constants'

const formatDate = 'YYYY-MM-DD ' + TimePattern.HH_MM

export function ExportExcel(
  column,
  dataSource,
  headerTitle,
  fileName = 'report',
) {
  const workbook = new Workbook()
  //  the new table
  let workSheet = workbook.addWorksheet('sheet-report')
  workSheet.properties = {
    ...workSheet.properties,
    outlineProperties: {
      summaryBelow: false,
      summaryRight: false,
    },
  } as any
  //  gets the number of header rows
  let depth = getDepth(column)

  //  gets the number of columns in the header
  let columnNum = getColumns(column)
  //init title row
  workSheet.mergeCells(1, 1, 1, columnNum)
  let titleCell = workSheet.getCell(1, 1)
  titleCell.value = headerTitle
  titleCell.alignment = { horizontal: 'center' }
  titleCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE5E5E5' },
  }
  titleCell.style.alignment = {
    horizontal: 'center',
    vertical: 'middle',
    wrapText: true,
  }
  workSheet.getRow(1).height = 30
  const TITLE_ROWS = 2

  //  initializes the header
  init(column, TITLE_ROWS, 0)
  //  unfold the columns in order
  let columnLineArr = []
  columnLine(column)
  //  according to the column, the dataSource the data inside is sorted and converted into a two-dimensional array
  let dataSourceArr = []

  let rowElementToColumnMapper = (ele, outlineLevel) => {
    let dataTemp = []
    columnLineArr.map((item) => {
      dataTemp.push({
        [item.dataIndex]: ele[item.dataIndex],
        value: ele[item.dataIndex]?.props.children,
        level: outlineLevel,
      })
    })
    dataSourceArr.push(dataTemp)
  }

  let recursiveRowTracer = (data, outlineLevel = 0) => {
    data.map((ele) => {
      rowElementToColumnMapper(ele, outlineLevel)
      if (ele.children?.length > 0)
        recursiveRowTracer(ele.children, outlineLevel + 1)
    })
  }

  //append dataSourceArr
  recursiveRowTracer(dataSource)

  //  drawing table data
  dataSourceArr.forEach((item, index) => {
    let row = workSheet.getRow(depth + index + TITLE_ROWS + 1)
    row.height = 15
    row.outlineLevel = item[0].level
    // row.hidden = item[0].level > 0 ? true : false
    // creates a cell for that number
    item.map((ele, ind) => {
      let cell = row.getCell(ind + 1)
      if (ele.level === 0)
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE5E5E5' },
        }
      if (ele.level === 1)
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF1F1FF' },
        }
      
      if(index === dataSourceArr.length - 1){
        cell.font = {
          color: { argb: 'FFEEEEEE' },
        }
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF4D4D4D' },
        }
      }
      if (ele.hasOwnProperty('num')) {
        cell.value = index + 1
      } else {
        cell.value = ele.value
      }

      cell.style.alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
      }
      let normalBorder: Partial<Border> = {
        color: { argb: '33CCCCCC' },
        style: 'thin',
      }
      cell.border = {
        top: normalBorder,
        bottom: normalBorder,
        left: normalBorder,
        right: normalBorder,
      }
    })
  })

  // set the width of each column
  // workSheet.columns?.forEach((column, index) => {
  //   if (index > 0) column.width = 25
  //   else column.width = 50
  // })

  workSheet.columns.forEach(function (column, i) {
    var maxLength = 20;
    column["eachCell"]({ includeEmpty: true }, function (cell,rowNumber) {
      if(rowNumber  <= 2)
        return
        var columnLength = cell.value ? cell.value.toString().length + 10 : maxLength;
        if (columnLength > maxLength ) {
            maxLength = columnLength;
        }
    });
    column.width = maxLength 
});

  //save xlsx
  workbook.xlsx
    .writeBuffer()
    .then(function (content) {
      saveAs(
        new Blob(
          [content],
          // {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}
        ),
        fileName + '.xlsx',
      )
    })
    .catch((err) => console.log(err, 'err in parsing to xlsx'))

  //  unfold the columns in order
  function columnLine(column) {
    column.map((ele) => {
      if (ele.children === undefined || ele.children.length === 0) {
        columnLineArr.push(ele)
      } else {
        columnLine(ele.children)
      }
    })
  }

  //  initializes the header
  function init(column, rowIndex, columnIndex) {
    let ci = columnIndex + 1 
    let ri = rowIndex + 1
    column.map((item, index) => {
      let hCell = workSheet.getCell(ri, ci)

      hCell.font = {
        bold: true,
        color: { argb: 'FFEEEEEE' },
      }
      hCell.style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF4D4D4D' },
      }
      let headerBorder: Partial<Border> = {
        color: { argb: '33999999' },
        style: 'thin',
      }
      hCell.style.alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
      }
      hCell.border = {
        top: headerBorder,
        left: headerBorder,
        bottom: headerBorder,
        right: headerBorder,
      }
      //  if there are no child elements,   all the columns
      if (item.title === ' operation ') {
        hCell.value = ''
        return
      } else if (item.children === undefined || item.children.length === 0) {
        //  add a cell to the first row
        hCell.value = item.title
        if (ri < depth + TITLE_ROWS)
          workSheet.mergeCells(ri, ci, ri + depth - 1, ci)
        ci++
      } else {
        let childrenNum = getColumnsChildrennum(item.children)
        workSheet.mergeCells(ri, ci, ri, ci + childrenNum - 1)
        hCell.value = item.title
        let rowCopy = ri - 1
        rowCopy++
        init(item.children, rowCopy, ci - 1)
        //  next cell start
        ci = ci + childrenNum 
      }
    })
  }

  //  gets table head rows
  function getDepth(arr) {
    const eleDepths = []
    arr.forEach((ele) => {
      let depth = 0
      if (Array.isArray(ele.children)) {
        depth = getDepth(ele.children)
      }
      eleDepths.push(depth)
    })
    return 1 + max(eleDepths)
  }

  function max(arr) {
    return arr.reduce((accu, curr) => {
      if (curr > accu) return curr
      return accu
    })
  }

  //  calculates the number of header columns
  function getColumns(arr) {
    let columnNum = 0
    arr.map((ele) => {
      if (ele.children) {
        columnNum += getColumns(ele.children)
      } else {
        columnNum++
      }
    })
    return columnNum
  }
}

function getColumnsChildrennum(arr, num = 0) {
  let childrenNum = num
  arr.map((ele) => {
    if (ele.children) {
      childrenNum = ele.children.getColumns(ele.children, num)
    } else {
      childrenNum++
    }
  })

  return childrenNum
}

export const daysDifference = (startDate: string, endDate: string) => {
  // let start = new Date(startDate+":0.0")
  // let end = new Date(endDate+":59.999")
  let start = new Date(startDate)
  let end = new Date(endDate)
  let dateDifference: number = end.getTime() - start.getTime()
  let numberOfDays = dateDifference / (1000 * 60 * 60 * 24)
  return numberOfDays
}

export const numberOfPages = async(startDate: string, endDate: string, interval: string) => {
    let end
    switch(interval){
        case 'hour':
            let initialStart = startDate
            let initialEnd = endDate
            if(moment.utc(startDate).format('YYYY-MM-DD') !== moment.utc(endDate).format('YYYY-MM-DD')){
                initialStart = moment.utc(startDate).startOf('day').format(formatDate)
                initialEnd = moment.utc(endDate).endOf('day').format(formatDate)
            }
            let days = daysDifference(initialStart, initialEnd)
            end = Math.round(days) > 1 ? moment.utc(startDate).endOf('day').format(formatDate) : endDate
            return {pages: Math.round(days), startPage: startDate, endPage: end}
    
        case 'day':
            let numberOfMonths = 0
            let formatEnd = new Date(endDate)
            let formatStart = new Date(startDate)
            if (formatEnd.getFullYear() === formatStart.getFullYear()) {
                numberOfMonths = formatEnd.getMonth() - formatStart.getMonth()
            } else {
                numberOfMonths =
                (formatEnd.getFullYear() - formatStart.getFullYear()) * 12
                numberOfMonths -= formatStart.getMonth()
                numberOfMonths += formatEnd.getMonth()
            }
            numberOfMonths++
            end = numberOfMonths > 1 ? moment.utc(startDate).endOf('month').format(formatDate) : endDate
            return {pages: numberOfMonths, startPage: startDate, endPage: end}
            
        default:
            return {pages: 1, startPage: startDate, endPage: endDate}
    }
}

export const updatePageRange = async(startDate: string, endDate: string, interval: string, page: number, lastPage: number) => {
    let start
    let end
    switch(interval){
        case 'hour':
            start = page !== 1 ? moment.utc(startDate).add(page - 1, 'day').startOf('day').format(formatDate) : startDate
            end = lastPage !== page ? moment.utc(start).endOf('day').format(formatDate) : endDate
            return {startPage: start, endPage: end}

        case 'day':
            start = page !== 1 ? moment.utc(startDate).add(page - 1, 'month').startOf('month').format(formatDate) : startDate
            end = lastPage !== page ? moment.utc(start).endOf('month').format(formatDate) : endDate
            return {startPage: start, endPage: end}

        default:
            return {startPage: startDate, endPage: endDate}
    }
}

export const formatScaling = (num : number) => {
  switch (true) {
    case num > 999 && num <= 999999:
      return (num / 1000).toFixed(0) + 'K';
    case num >= 1000000 && num <= 99999999:
      return (num / 1000000).toFixed(0) + 'M';
    case num >= 1000000000:
      return (num / 1000000000).toFixed(0) + 'B';
    default:
      return num;
  }
}

// To get highest frequency
export const getHighestFrequency = (startDateTime: string, endDateTime: string ) => {
  let highestfrequency: string
  let days = daysDifference(startDateTime, endDateTime)
  switch (true) {
    case days >= 31:
    highestfrequency  =FrequencyDropdown.MONTH_VALUE
      break
    case days >= 1:
      highestfrequency=FrequencyDropdown.DAY_VALUE
      break
    default:
      highestfrequency=FrequencyDropdown.HOUR_VALUE
  }
  return highestfrequency
}

//color code
export const colorCodes = [
  '#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00',
  '#ffff33', '#a65628', '#f781bf', '#999999',
  '#1f77b4', '#aec7e8', '#ffbb78', '#2ca02c', '#98df8a',
  '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b',
  '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7',
  '#bcbd22', '#dbdb8d', '#17becf', '#9edae5', '#f0f0f0',
  '#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e',
  '#e6ab02', '#a6761d', '#666666', '#dd3497', '#74c476',
  '#de8f05', '#fccde5', '#d73027', '#fc8d59', '#1b7837',
  '#74a9cf', '#feb24c', '#2b8cbe', '#fa9fb5', '#a6dba0',
  '#d9d9d9', '#f0b3dc', '#beaed4', '#c6dbef', '#fcbba1',
  '#ff7f00', '#cab2d6', '#6a3d9a', '#ffbb78', '#2ca02c',
  '#ffea99', '#1f77b4', '#8c564b', '#baa6d2', '#7b4173',
];

export const getColorScheme =(colorKeys:any,scheme:any)=>{
  const resultObject = colorKeys?.reduce((acc:any, item:any, index:any) => {
    acc[item] = scheme[index] || ''; // Use an empty string if there are more keys than colors
    return acc;
  }, {});
  return resultObject
}

export const newGraphData=(inputBins:any)=>{
  const outputData = inputBins.map((bin, index) => {
      const binKey = bin.data[0].key;
      const hue = (360 / 50) * index;
      const color = `hsl(${hue}, 70%, 50%)`;
      return {
          id: binKey,
          color: color,
          data: bin.data[0].data,
          key: bin.data[0].key,
          total: bin.data[0].total,
          currency: bin.data[0].currency,
      };
  });
  const finalOutput = {
      header:`Sorting: ${inputBins && inputBins[0]?.header?(inputBins[0]?.header)?.split(':')[1]:""}`,
      data: outputData,
  };
  return [finalOutput]
  }

  export const getCoumnsKeys=(data:any)=>{
    let columnIds = [];
    for (let row of data.rows) {
      for (let loc of row.location) {
        if (loc?.isAMHLocation) {
          const newColumnIds = data?.columns?.map((col:any) => {
            const newId = `${loc.locationName}.${col.id}`;
            return newId;
          })
          columnIds = [...columnIds, ...newColumnIds]
        }
      }
      break
    }
    
    return columnIds
   }

  export const convertForGraphData =(rows:any)=>{
    const binSums = {};
    for (let row of rows) {
      for (let loc of row.location) {
        if (loc?.isAMHLocation) {
          for (const bin in loc.data) {
            const binName = `${loc.locationName}.${bin}`;
            binSums[binName] = (binSums[binName] || 0) + loc.data[bin];
          }
        }
      }
    }

    const output = Object.keys(binSums)?.map(bin => ({
      id: bin,
      label: bin,
      value: binSums[bin],
      color: `hsl(${Math.floor(Math.random() * 361)}, 70%, 50%)`,
    }));
    return output
  }