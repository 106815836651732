/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import { Table, Empty,Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import '../InsightHeader/insightHeader.less'
import './insightTable.less'
import { setEventDataValues, insightsSelector, setShowEventLog } from '../../../../../redux/reducers/insights/insightsReducer'
import Loader from '../../../stateless/common/spinner'
import { insightColumns } from './insightData'
import moment from 'moment-timezone'
import { AppRoutes } from '../../../../constants/enums'
import { getFormattedDate, getHeight, getOrganizationDateAndTime, translateOptions, translateTaleColum, urlModification } from '../../../../../utility/appUtil'
import { ReportRowData, ReportLocation, ReportDevice, ReportColumnData, ReportDevicePart } from '../../../../models/insights/reports'
import {ExportExcel} from '../../../../../utility/insightUtils'
import { organisationDetailsSelector } from '../../../../../redux/reducers/organisation/organisationDetailsReducer'
import { FrequencyDropdown, TimePattern } from '../../../../constants/constants'
import { locationListSelector } from '../../../../../redux/reducers/location/locationListReducer'
import { DeviceSelector } from '../../../../../redux/reducers/device/deviceReducer'
import { deviceDeleteIcon } from '../../../../images'
import { EmptyDevice } from '../../device/deviceComponents/index.styles'
import { useTranslation } from 'react-i18next';

const InsightTable = (props: any) => {
  const {startPage, endPage, groupByValue, showLoading, pages, insightHeaderHeight, exportData,
    setBreakFrom, setBreakTo, selectedLocations, selectedDevices,getInsightsDateLabels,onlyCloudCheck, setInsightHasData, inisghtReportValue
  } = props
  const { t } = useTranslation()
  const location: any = useLocation()
  const dispatch = useDispatch()
  const { formState, reportDataFormState, reportData } = useSelector(insightsSelector)
  const { locations } = useSelector(locationListSelector)
  const { deviceList, deviceListFilter } = useSelector(DeviceSelector)
  const { organisation} = useSelector(organisationDetailsSelector)
  const [scrollHeight, setScrollHeight] = useState(200);
  const [tableData, setTableData] = useState([])
  const history = useHistory()
  const [col,setCol] = useState(insightColumns(t))
  const [filteredCol,setFilterdCol] = useState([])
  const [filterValues, setFilterValues] = useState({location: [], device: []})
  const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
  const [cloudCheckCol, setCloudCheckCol] = useState<any>(true)

  useEffect(() => {
    const node = document.body
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenHeight(node?.clientHeight)
    });
    resizeObserver.observe(node);
  }, [])

  useEffect(() => {
    setFilterValues({
      location: selectedLocations.length < locations.length ? selectedLocations : null, 
      device: selectedDevices.length < deviceList.length ? selectedDevices : null
    })
  }, [selectedLocations,selectedDevices])

  useEffect( () => {
    if(!reportDataFormState?.loading && !formState?.loading && !showLoading){
      const compContentHeight = getHeight('.ant-layout-content')
      const bottomGap = compContentHeight - (screenHeight * 0.8)
      const tableHeight = getHeight('.ant-table-body') - bottomGap
      const scrollH = parseInt(tableHeight.toFixed(0));
      scrollH && !isNaN(scrollH) && setScrollHeight(scrollH)
    }
  }, [col, tableData, reportDataFormState?.loading, formState?.loading, showLoading, insightHeaderHeight, screenHeight])

  useEffect(() => {
    let headerDateFormat = "DD MMMM YYYY," + TimePattern.HH_MM
    let fileDateFormat = "DMMMMYYYY," + TimePattern.HH_MM
    if(exportData){
      const currentTime = getOrganizationDateAndTime(new Date().toISOString(), headerDateFormat)    
      const organizationName = organisation?.result?.name
      const from = getFormattedDate(startPage,headerDateFormat)
      const to = getFormattedDate(endPage,headerDateFormat)
      const headerTitle = "Organisation: " + organizationName + " ,   From: " + from + " ,   To: " + to + " ,   Exported at: " + currentTime;
      const csvFileName =
      getFormattedDate(startPage,fileDateFormat) +
      "_" +
      getFormattedDate(endPage,fileDateFormat) +
      "_report";
      ExportExcel(col,tableData,headerTitle, csvFileName)
      props.setExportData(false)
    }
  }, [exportData])

  const getRowTitle = () => {
    if(groupByValue){
      return ''
    }
    switch(reportData.interval){
      case 'hour':
        return getFormattedDate(startPage,reportData.locale.dateFormat)
      case 'day':
        if(getFormattedDate(startPage,'YYYY') !== getFormattedDate(endPage,'YYYY')){
          return getFormattedDate(startPage,'MMMM YYYY') + '-' + getFormattedDate(endPage,'MMMM YYYY')
        }
        else if(getFormattedDate(startPage,'MMMM')!== getFormattedDate(endPage,'MMMM')){
          return getFormattedDate(startPage,'MMMM') + '-' + getFormattedDate(endPage,'MMMM YYYY')
        }
        return getFormattedDate(startPage,'MMMM YYYY')
      case 'month':
        if(getFormattedDate(startPage,'YYYY') !== getFormattedDate(endPage,'YYYY')) {
          return getFormattedDate(startPage,'MMMM YYYY') + '-' + getFormattedDate(endPage,'MMMM YYYY')
        }
        return getFormattedDate(startPage,'YYYY')
      default:
        return ''
    }
  }
  const getReportDate = (value: any,groupByValue:any) => {
    if(groupByValue)
    {
    switch(reportData.interval){
      case 'hour':
        return getInsightsDateLabels(value , TimePattern.HH_MM)
      case 'day':
        return getInsightsDateLabels(value,'dddd')
      default:
        return getInsightsDateLabels(value , 'MMMM')
    }
  }
  else{
    switch(reportData.interval){
      case 'hour':
        return getInsightsDateLabels(value ,TimePattern.HH_MM)
      case 'day':
        return getInsightsDateLabels(value,reportData.locale.dateFormat)
      default:
        return getInsightsDateLabels(value ,'MMMM YYYY')
    }
  }
  }

  const updateColumns = () => {
    let colValue : any = [
      {
        width: 50,
        title: getRowTitle(),
        dataIndex: ['reportData'],
        ellipsis: true,
        fixed: 'left'
      }
    ]
    reportData.columns?.forEach((x:ReportColumnData)=>{
      let colData : any = {}
      let entered = false
      if(colValue.length){
        colValue.forEach((item:any)=>{
          if(item.title.toString() === x.header.toString()){
            item.children?.push({
              width: 50,
              title: <div className={(x.subHeader==="Total") ? 'insightBold-col' : ''}>{x.subHeader}</div>,
              dataIndex: [x.id], 
              ellipsis: true,
            })
            entered = true
          }
        })
      }
      if(!entered){
        colData.title = x.header
        colData.children = []
        colData.children?.push({
          width: 50,
          title: x.subHeader,
          dataIndex: [x.id],
          ellipsis: true,
        })
        colData.ellipsis = true
        colValue.push(colData)
      }
    })
    return colValue
  }
  useEffect(()=>{
    setCol(updateColumns)
  },[reportDataFormState?.isSuccess])

  useEffect(() => {
    
    if (location?.pathname.includes("insights/saved")) {
      if (onlyCloudCheck) {
        setFilterdCol(col?.filter((item: any) => {
          if (item?.title !== 'Payments' && item?.title !== 'Recommendations') {
            return item
          }
        }))
      } else {
        let deviceTypes: any = []
        for (let i = 0; i < selectedDevices.length; i++) {
          let deviceTypesArray: any = deviceListFilter?.rows?.filter((device: any) => device.id === selectedDevices[i])
          deviceTypesArray && deviceTypes.push(deviceTypesArray[0]?.deviceType)
        }
        let otherThanCloudCheck = deviceTypes?.some(device => !device?.includes('cloudCheck'))
        if(otherThanCloudCheck || !selectedDevices.length ) {
          setFilterdCol(col)
        } else {
          // setFilterdCol(col)
          setFilterdCol(col?.filter((item: any) => {
            if (item?.title !== 'Payments' && item?.title !== 'Recommendations') {
              return item
            }
          }))
        }
      }
    } else {
      if (onlyCloudCheck) {
        setFilterdCol(col?.filter((item: any) => {
          if (item?.title !== 'Payments' && item?.title !== 'Recommendations') {
            return item
          }
        }))
      } else {
        setFilterdCol(col)
      }
    }
  }, [col, deviceListFilter])

  const modifyRange = async(range: any) => {
    let dateRange :any = {}
    let rangeFormat = 'YYYY-MM-DD' + ' ' + TimePattern.HH_MM
    let currentRange = getInsightsDateLabels(range,rangeFormat)
    switch(reportData?.interval){
      case 'day':
        dateRange.end =  moment.utc(currentRange).endOf('day').format(rangeFormat)
        dateRange.start = moment.utc(currentRange).startOf('day').format(rangeFormat)
        return dateRange
      case 'hour':
        dateRange.end =  moment.utc(currentRange).endOf('hour').format(rangeFormat)
        dateRange.start = moment.utc(currentRange).startOf('hour').format(rangeFormat)
        return dateRange
      default:
        dateRange.end =  moment.utc(currentRange).endOf('month').format(rangeFormat)
        dateRange.start =  moment.utc(currentRange).startOf('month').format(rangeFormat)
        return dateRange
    }
  }

  const goToEventLog = (range: any, eventIds:number[], filters: any, fetchAll: boolean, count: number) => {
    dispatch(setShowEventLog(true))
    if(fetchAll){
      dispatch(setEventDataValues({
        start: range.start,
        end: range.end,
        eventIds: eventIds,
        filters: filters,
        count: count
      }))
      history.push(urlModification(AppRoutes.EVENT_LOGS))
    }
    else{
      modifyRange(range).then((data:any)=>{
        dispatch(setEventDataValues({
          start: data.start,
          end: data.end,
          eventIds: eventIds,
          filters: filters,
          count: count
        }))
        history.push(urlModification(AppRoutes.EVENT_LOGS))
      })
    }    
  }

  const getDataCount = (col,count) => {
    if(col?.info?.isCurrency)
    {
      return col?.info?.currency + count.toFixed(2);
    }
    else
    {
      return count
      }   
     }
  
  const dataLayout = (reportRow: any, data: any, range: any, filter: any, totalData?: boolean) => {
    let dataKeys: any = {...reportRow};
    for(let key in data.data){
      const col = reportData.columns.filter((col:any) => col.id.toString() === key.toString())
      dataKeys = {
        ...dataKeys, 
        [col[0]?.id] : (
          <span 
            tabIndex = {
                ((col[0]?.header === "Footfall" && col[0]?.subHeader !== "Detections") || (col[0]?.subHeader === "Amount Entered")) ?
                null : data.data[key] > 0 && !groupByValue ? 0 : -1
            }
            className = {
                `${
                 ((col[0]?.header === "Footfall" && col[0]?.subHeader !== "Detections")|| (col[0]?.subHeader === "Amount Entered")) 
                 ? null : data.data[key] > 0 && !groupByValue && "insight-count"} 
                
                ${(col[0]?.header === "Footfall" && col[0]?.subHeader !== "Detections") ? null : totalData && "insight-total" }`
            }
            onKeyPress = {
                () => (col[0]?.header === "Footfall" && col[0]?.subHeader !== "Detections") || (col[0]?.subHeader === "Amount Entered") ?
                null : data.data[key] > 0 && !groupByValue && goToEventLog(range, col[0].eventIds, filter, totalData, data.data[key])
            }
            onClick = {
                () => (col[0]?.header === "Footfall" && col[0]?.subHeader !== "Detections") || (col[0]?.subHeader === "Amount Entered") ?
                null : data.data[key] > 0 && !groupByValue && goToEventLog(range, col[0].eventIds, filter, totalData, data.data[key])
            }
          >
            {
              getDataCount(col[0], data.data[key])
            }
          </span>
        ),
      }
    }
    if (data?.location?.length) {
      dataKeys.children = data.location.map((childData: ReportLocation) => {
        let locationKey = {
          key: range+childData.locationId,
          reportData: <span>{childData.locationName}</span>,
        };
        return dataLayout(locationKey, childData, range, {location: [childData.locationId], device: filterValues?.device ? [...filterValues?.device] : null});
      });
    }
    if (data?.device?.length) {
      dataKeys.children = data.device.map((childData: ReportDevice) => {
        let deviceKey = {
          key: range+childData.deviceId,
          reportData:<span className='deviceRowDivStye'>
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={childData.deviceName}>
            <span className='deviceNamestyle'>{childData.deviceName}</span></Tooltip>
            {childData?.deletedAt ?  
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Deleted">
          <img src={deviceDeleteIcon} alt="" className='InsightdeviceDeleteIcon'/>
          </Tooltip> : ""}
          </span>,
        };
        return dataLayout(deviceKey, childData, range, {location: [data.locationId] , device: [childData.deviceId]});
      });
    }
    if (data?.parts?.length) {
      dataKeys.children = data.parts.map((childData: ReportDevicePart) => {
        let partsKey = {
          key: range+childData?.partName,
          reportData: <span>{childData?.partName}</span>,
        };
        return dataLayout(partsKey, childData, range, {location: [data?.locationId] , device: [data?.deviceId] , parts : [childData?.partName]});
      });
    }
    return dataKeys;
  };

  const freqBreakdown = (range: any) => {
    modifyRange(range).then((data:any)=>{
      setBreakFrom(data.start)
      setBreakTo(data.end)
    })
  }

  const reportTableData = async(data : ReportRowData[]) => {
    let total = { 
      info: {
        key:'reportTotal',
        reportData: <span  className='insight-total'>Total</span>
      },
      data:null
    }
    const tableInfo = data?.map((rowData : ReportRowData, index : number) =>{
      const reportDate =  getReportDate(rowData.date,groupByValue)
      let dataKeys = {
        key: rowData.date,
        reportData: <span tabIndex={reportData.interval !== 'hour' && !groupByValue? 0 : -1} onKeyPress={()=>{reportData.interval !== 'hour'&& !groupByValue && freqBreakdown(rowData.date)}} className={`${reportData.interval !== 'hour' && !groupByValue? "insight-val" : ''}`} onClick={()=>{reportData.interval !== 'hour'&& !groupByValue && freqBreakdown(rowData.date)}}>{reportDate}</span>,
      }; 
      if(!total.data){
        total.data = rowData.data
      }
      else{
        for(let key in rowData.data){
            total.data = {...total.data,[key]: (total.data[key] || 0 ) + rowData.data[key]}
        }
      }
      return dataLayout(dataKeys, rowData, rowData.date, filterValues)
    })
    tableInfo && tableInfo.push( dataLayout(total.info, total, {start: startPage, end: endPage}, filterValues, true) )
    return tableInfo
  }

  useEffect(() => {
    reportTableData(reportData.rows).then((data: any)=>{
      if(groupByValue && reportData?.interval == FrequencyDropdown?.DAY_VALUE){
        let [first, ...rest] = data;
        let lastEl = rest[rest.length - 1]
        rest = rest.splice(0,rest.length-1)
        data = [...rest,first,lastEl]
      }
      setTableData(data)
      const dataValue = data?.length ? true : reportData?.fetchFootfall ? true : false
      setInsightHasData(dataValue)
    })
  }, [reportDataFormState?.isSuccess, groupByValue])
  
  return (
    <>
      <Loader loading={reportDataFormState?.loading || formState?.loading || showLoading}>
          {!reportDataFormState?.isError && tableData?.length ?
          <Table 
            className={`insight-table ${inisghtReportValue ? "subChildHide" : ""}`}
            size="small"
            columns={filteredCol}
            dataSource={tableData}
            pagination={false}
            rowClassName="insight-table-row"
            //@ts-ignore
            scroll={{ y:scrollHeight - ((pages*10) > 10 && !groupByValue ? 48 : 0), x:true }}
          />
          :
          reportDataFormState?.isError && <EmptyDevice description='Error Occurred'/>
          }
        </Loader>
    </>
  )
}

export default InsightTable

export const PaymentDetailTable = (props: any) => {
  const {groupByValue, showLoading, pages, insightHeaderHeight, setInsightHasData, inisghtReportValue, getInsightsDateLabels} = props;
  const { t } = useTranslation();
  const { formState, reportDataFormState, reportData } = useSelector(insightsSelector);
  const [scrollHeight, setScrollHeight] = useState(200);
  const [tableData, setTableData] = useState([]);
  const [col,setCol] = useState(insightColumns(t));
  const [filteredCol,setFilterdCol] = useState([]);
  const [screenHeight, setScreenHeight] = useState(document.body.clientHeight);
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const node = document.body
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenHeight(node?.clientHeight)
    });
    resizeObserver.observe(node);
  }, []);

  useEffect( () => {
    if (!reportDataFormState?.loading && !formState?.loading && !showLoading) {
      const compContentHeight = getHeight('.ant-layout-content')
      const bottomGap = compContentHeight - (screenHeight * 0.8)
      const tableHeight = getHeight('.ant-table-body') - bottomGap
      const scrollH = parseInt(tableHeight.toFixed(0));
      scrollH && !isNaN(scrollH) && setScrollHeight(scrollH)
    }
  }, [col, tableData, reportDataFormState?.loading, formState?.loading, showLoading, insightHeaderHeight, screenHeight]);

  useEffect(() => {
    setFilterdCol(col)
  }, [col]);

  const updateColumns = () => {
    let colValue : any = []
    reportData.columns?.forEach((item: ReportColumnData) => {
      let colData : any = {
        title: item.header,
        dataIndex: [item?.header],
        ellipsis: true
      }
      colValue.push(colData)
    })
    return colValue
  }

  useEffect(() => {
    setCol(updateColumns)
  }, [reportDataFormState?.isSuccess]);

  const modifyRange = async(range: any) => {
    let dateRange :any = {}
    let rangeFormat = 'YYYY-MM-DD' + ' ' + TimePattern.HH_MM
    let currentRange = getInsightsDateLabels(range,rangeFormat)
    switch(reportData?.interval){
      case 'day':
        dateRange.end =  moment.utc(currentRange).endOf('day').format(rangeFormat)
        dateRange.start = moment.utc(currentRange).startOf('day').format(rangeFormat)
        return dateRange
      case 'hour':
        dateRange.end =  moment.utc(currentRange).endOf('hour').format(rangeFormat)
        dateRange.start = moment.utc(currentRange).startOf('hour').format(rangeFormat)
        return dateRange
      default:
        dateRange.end =  moment.utc(currentRange).endOf('month').format(rangeFormat)
        dateRange.start =  moment.utc(currentRange).startOf('month').format(rangeFormat)
        return dateRange
    }
  }

  const goToEventLog = (record: any) => {
    const {
      eventTimestamp,
      eventId,
      eventSrc,
    } = record;

    const locationState = {
      eventOfAlertStatus: true,
      activeAlertTimestamp: eventTimestamp,
      alertTypeId: eventId,
      deviceId: eventSrc,
    };
    history.push(urlModification(AppRoutes.EVENT_LOGS), locationState);
  };

  const getRowValue = (col: any, rowValue: number) => {
    if (col?.info?.isCurrency && rowValue) {
      return col?.info?.currency + rowValue.toFixed(2);
    } else {
      return rowValue
    }   
  }

  const createTableData = () => {
    const tableInfo = [];
    reportData.rows?.map((row)=> {
      let dataKeys = {};
      for (let key in row.data) {
        const col = reportData.columns.filter((col:any) => col.header === key)
        dataKeys = {
          ...dataKeys,
          [col[0]?.header] : (
            <span 
              tabIndex = {
                  (col[0]?.header === "Date & Time") ? 0 : -1
              }
              className = {
                  `${(col[0]?.header === "Date & Time") ? "insight-count" : ''}`
              }
              onKeyPress = {
                  () => (col[0]?.header === "Date & Time") ? goToEventLog(row.data) : null
              }
              onClick = {
                  () => (col[0]?.header === "Date & Time") ? goToEventLog(row.data) :  null 
              }
            >
              {
                getRowValue(col[0],row.data[key])
              }
            </span>
          ),
        }
      }
      tableInfo.push(dataKeys)
    })

    return tableInfo
  }

  useEffect(() => {
    let data = createTableData()
    setTableData(data)
    const dataValue = reportData.rows?.length ? true : false
    setInsightHasData(dataValue)
  }, [reportDataFormState?.isSuccess, groupByValue]);

  return (
    <>
      <Loader loading={reportDataFormState?.loading || formState?.loading || showLoading}>
        {!reportDataFormState?.isError && tableData?.length ?
          <Table
            className={`payment-details-table ${inisghtReportValue ? "subChildHide" : ""}`}
            size="middle"
            columns={filteredCol}
            dataSource={tableData}
            pagination={false}
            rowClassName="insight-table-row"
            //@ts-ignore
            scroll={{ y: scrollHeight - ((pages * 10) > 10 && !groupByValue ? 48 : 0), x: true }}
          />
        :
          reportDataFormState?.isError && <EmptyDevice description='Error Occurred'/>
        }
      </Loader>
    </>
  )
}