/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect,useState} from "react";
import { Row, Col, Form, Input } from "antd";
import { ProfilePicture } from '../../../../stateless/user/profile/profilePicture';
import {Dropdown} from "../../../../../../bit_components/common/dropdown";
// import MultipleSelect from "../../../common/multiSelect";
import {Multiselect} from "../../../../../../bit_components/common/multiselect";
import './userProfileForm.less';
import {concatNames,convertPhoneNumberToUserFormat,convertUserFormatToPhoneNumber} from "../../../../../../utility/appUtil";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { useSelector } from "react-redux";
import { UserRole } from "../../../../../constants/enums";
import { userProfileSelector } from "../../../../../../redux/reducers/user/userProfileReducer";
import { AppError } from "../../../common/appError";
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';
import { sortList } from "../../../../../../utility/utils";

export const UserProfileForm = (props: any) => {
  const { t } = useTranslation()
  const {appUser} = useSelector(userStateSelector);
  const { formName, form, formLayout, user, roles, selectedRoleId, avatarColorList, selectRoleCallback, selectLocationsCallback, locations = [], selectedLocations, formSubmitCallback, formChangedCallback, locationValidationMsg, isEditAllowed ,roleId } = props;
  const { isProfileSaved, errorMsg, formState } = useSelector(userProfileSelector);
  const [phoneNumber, setPhoneNumber] = useState<any>()
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false)

  useEffect(()=>{
    form.setFieldsValue({
      fullName: concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title),
      email: user?.email,
      // phone: convertPhoneNumberToUserFormat(user?.phone)
      phone: user?.phone
    });
  },[user])

  const scrollToTop = () => {
    const node = document.querySelector<HTMLElement>(".scrollContent");
    node?.scrollTo(0, 0);
  }

  useEffect(() => {
    if(!isProfileSaved && formState?.isError){
      scrollToTop();
    }
  },[formState, isProfileSaved])

  const getRoles = () => {
    let arrRole: any[] = [];
    if (roles && roles.length) {
      arrRole = arrRole.concat(
        roles.map((x: any) => {
          return { text: x.name, value: x.id };
        })
      ).sort(sortList('text'));
    }
    return arrRole;
  };

  const onPhoneNumberChange = (event: any) => {
    let data = convertUserFormatToPhoneNumber(event?.target?.value)
    if (data?.isValidNumber) {
      setPhoneNumber(data?.formatNumber)
      setInvalidPhoneNumber(false)
    } else {
      setInvalidPhoneNumber(true)
    }
  }

  const getLocations = () => {
    const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id
      };
    }).sort(sortList('label'));
    return arrLocation;
  };


  return (
    <div className="userProfileForm pt-57 pl-vw">
      <AppError show={!isProfileSaved && formState.isError ? true: false} errorMsg={errorMsg} />
      <Form
        {...formLayout}
        name={formName}
        form={form}
        className="profile-form"
        initialValues={{
          role: selectedRoleId,
          remember: true,
        }}
        onChange={formChangedCallback}
        onFinish={formSubmitCallback}
      >
        <Row align="top" className="pr-41 userProfileRow">
          <Col id="inner-col" span={24} lg={6} className="profPicCol">
            <ProfilePicture src={user?.profileUrl} firstName={user?.firstName} lastName={user?.lastName} colors={avatarColorList} round={true} size="240" />
          </Col>
          <Col id="inner-col" span={24} lg={16} offset={2} className="userForm" >
            <Row >
              <Col span={11}>
              <Form.Item
                  label={t(jsonData.Username)}
                  name="username"
                  initialValue={user?.userName}
                  className='profile-label-cls'
                  rules={[
                    {
                      required: true,
                      message: t(jsonData.userNameValidation), // TODO: Handle this while developing validation framework.
                    },]}
                >
                  <Input className='profile-input-cls'
                  disabled={true}/>
                </Form.Item>

                {/* <Form.Item
                  label="Full Name"
                  name="fullName"
                  initialValue={concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title)}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Full Name!', // TODO: Handle this while developing validation framework.
                    },
                    {
                      max: 100,
                      message: "Please enter a Name shorter than 100 character long."
                    },
                  ]}
                  className='profile-label-cls'
                >
                  <Input size='large' className='profile-input-cls'
                    //  disabled={!isEditAllowed || (user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER))}
                    disabled={true}
                  />
                
                </Form.Item> */}
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  name="role"
                  label={t(jsonData.Role)}
                  rules={[
                    {
                      required: true,
                      message: t(jsonData.roleValidation), // TODO: Handle this while developing validation framework.
                    },
                  ]}
                  className='profile-label-cls'
                >
                  <Dropdown
                    disabled={appUser && (appUser.id === user.id || !isEditAllowed || (user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER) ) )? true:false}
                    optionValue={getRoles()}
                    onChange={selectRoleCallback}
                    defaultValue = "Select Role"
                    tabIndex={0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="pt-30">
              <Col span={11}>

              <Form.Item
                  label={t(jsonData.FullName)}
                  name="fullName"
                  initialValue={concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title)}
                  rules={[
                    {
                      required: true,
                      message:t(jsonData.PleaseInputYourFullName), // TODO: Handle this while developing validation framework.
                    },
                    {
                      max: 100,
                      message: t(jsonData.PleaseEnterNameShorterThan100Chars)
                    },
                  ]}
                  className='profile-label-cls'
                >
                  <Input size='large' className='profile-input-cls'
                    //  disabled={!isEditAllowed || (user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER))}
                    disabled={true}
                  />
                
                </Form.Item>


                {/* <Form.Item
                  label="Email Address"
                  name="email"
                  initialValue={user?.email}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Email Address!', // TODO: Handle this while developing validation framework.
                    },
                  ]}
                  className='profile-label-cls'
                >
                  <Input disabled={true} className='profile-input-cls'/>
                </Form.Item> */}
              </Col>
              <Col span={11} offset={2}>
              <Form.Item
                  label={t(jsonData.EmailAddress)}
                  name="email"
                  initialValue={user?.email}
                  rules={[
                    {
                      required: true,
                      message: t(jsonData.addressValidation), // TODO: Handle this while developing validation framework.
                    },
                  ]}
                  className='profile-label-cls'
                >
                  <Input disabled={true} className='profile-input-cls'/>
                </Form.Item>
              {/* <Form.Item
                  label="Username"
                  name="username"
                  initialValue={user?.userName}
                  className='profile-label-cls'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Username!', // TODO: Handle this while developing validation framework.
                    },]}
                >
                  <Input className='profile-input-cls'
                  disabled={true}/>
                </Form.Item> */}
              </Col>
            </Row>
            <Row className="pt-30">
              <Col span={11}>
                <Form.Item
                  label={t(jsonData.PhoneNumber)}
                  name="phone"
                  initialValue={user?.phone}
                  className='profile-label-cls'
                >
                  <Input className='profile-input-cls'
                  //  disabled={!isEditAllowed || (user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER))} 
                  disabled={true}
                  onChange={onPhoneNumberChange}/>
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
              <Form.Item
               name="location"
               label={t(jsonData.Locations)}
               className="profile-label-cls"
               rules={[
                 ({ getFieldValue }) => ({
                   validator(_, value) {
                     const selected = selectedLocations;
                     if (selected && selected.length) {
                       return Promise.resolve();
                     }
                     return Promise.reject(new Error(t(jsonData.errorLoaction))); // TODO: Handle this while developing validation framework.
                   },
                 })
               ]}
             >
               {/* Add css 'ant-input' on div.dropdown-heading-value */}
              {
              roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ?
              <div className='branches-wrapper-for-0 disableIcon'>
              <div className='checkbox-label-cls-unselected'>{t(jsonData.AllLocationSelected)}</div></div>:
               <div className={`${formName}_locations locations-multi-select ant-row ant-form-item ant-form-item-with-help profile-label-cls`}>
               <div className="ant-col ant-col-24 ant-form-item-control userProfileMultiselect">
                 <div className="ant-form-item-control-input">
                   <div className= {roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ?"ant-form-item-control-input-content grayBackground":"ant-form-item-control-input-content "}>
                     <Multiselect
                       allowSearch={true}
                       labelledBy={t(jsonData.SelectLocations)}
                       value={selectedLocations}
                       options={getLocations()}
                       onChange={selectLocationsCallback}
                       overrideStrings={{'allItemsAreSelected': t(jsonData.allLocationSelected)}}
                       disabled={roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN}
                     />
                   </div>
                 </div>
                 </div>
                 </div>
                  }
               </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
