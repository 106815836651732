/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import InsightHeader from "./InsightHeader/InsightHeader";
import { Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  clearReportDataFormSate,
  clearReports,
  clearState,
  insightsSelector,
} from "../../../../redux/reducers/insights/insightsReducer";
import { ReportColumnData, ReportData } from "../../../models/insights/reports";
import moment from "moment-timezone";
import "./InsightHeader/insightHeader.less";
import {
  DefaultTime,
  DurationDropDownConstants,
  FrequencyDropdown,
  InsightRepresentation,
  TimePattern,
  jsonData,
  reportTypes,
} from "../../../constants/constants";
import InsightGraph from "./insightGraph";
import InsightTable, { PaymentDetailTable } from "./insightTable";
import { Serie } from "@nivo/line";
import {
  convertForGraphData,
  daysDifference,
  getCoumnsKeys,
  getHighestFrequency,
} from "../../../../utility/insightUtils";
import { fetchOrganisationbyID } from "../../../../redux/actions/organisation/fetchOrganisationDetails";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import {
  cameFromOrganisation,
  getClientTimeZoneOffset,
  getFormattedDate,
  translateGraphText
} from "../../../../utility/appUtil";
import { appSelector } from "../../../../redux/reducers/app/appReducers";
import { ReportScreen } from "./insights.styles";
import AntdPagination from "../../stateless/common/pagination/pagination";
import { organisationDetailsSelector } from "../../../../redux/reducers/organisation/organisationDetailsReducer";
import { useTranslation } from "react-i18next";
import { UseGAEventTracker } from "../../../../utility/useGAEventTracker";
import { AppService } from "../../../services/ui/appService";

const Insights = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const graphRef = useRef<any>();
  const {
    reportData,
    prevReport,
    reportValues,
    reportDataFormState,
    formState,
    viewDataValues,
  } = useSelector(insightsSelector);
  const location: any = useLocation();
  const { appUser } = useSelector(userStateSelector);
  const { selectedOrganisation } = useSelector(appSelector);
  const { organisation} = useSelector(organisationDetailsSelector)
  const [startTime, setStartTime] = useState(DefaultTime.START);
  const [endTime, setEndTime] = useState(DefaultTime.END);
  const [chartData, setChartData] = useState<any>([]);
  const chartTimeFormat = "YYYY-MM-DD " + TimePattern.HH_MM;
  const [insightHeaderHeight, setInsightHeaderHeight] = useState(64);
  const [activeRepresentation, setActiveRepresentation] = useState<any>(
    InsightRepresentation.TABULAR
  );
  const [groupByValue, setGroupByValue] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [onlyCloudCheck, setOnlyCloudCheck] = useState<boolean>(false);
  const [pageChanged, setPageChanged] = useState<boolean>(false);
  const [pages, setPages] = useState(0);
  const [startPage, setStartPage] = useState(moment().format(chartTimeFormat));
  const [endPage, setEndPage] = useState(moment().format(chartTimeFormat));
  const [currentPage, setCurrentPage] = useState(1);
  const [exportData, setExportData] = useState<boolean>(false);
  const [breakFrom, setBreakFrom] = useState(null);
  const [breakTo, setBreakTo] = useState(null);
  const [downloadingGraph, setDownloadingGraph] = useState<boolean>(false)
  const [reportViewValue, setReportViewValue] = useState<any>("");
  const [locations, setLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState("day");
  const [insighthasData, setInsightHasData] = useState<boolean>(true)
  const [inisghtReportValue, setInisghtReportValue] = useState<boolean>(false)

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };
  useEffect(() => {
    if (location?.state?.Viewable || location?.state?.editable) {
      setActiveRepresentation(
        viewDataValues?.filters?.dataRepresentation
          ? viewDataValues?.filters?.dataRepresentation
          : InsightRepresentation.TABULAR
      );
    }
  }, [viewDataValues]);

  useEffect(() => {
    if (activeRepresentation === InsightRepresentation.GRAPHICAL ) {
      UseGAEventTracker("Insights Category", "insight graph view", `Selected insight ${activeRepresentation}`);
    }else{
      UseGAEventTracker("Insights Category", "insight table view", `Selected insight ${activeRepresentation}`);
    }
  }, [activeRepresentation]);

  useEffect(() => {
    dispatch(fetchOrganisationbyID(getOrgId()));
    return () => {
      dispatch(clearReports());
      dispatch(clearReportDataFormSate());
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    setOnlyCloudCheck(organisation?.result?.onlyCloudCheck)
  }, [organisation])
  
  // selectors for reports
  const [format, setFormat] = useState<{
    x?: {
      format: string;
      scale: {
        precision: any;
        format: string;
        tickValue: any;
        min?: any;
        max?: any;
      };
    };
    y?: {};
  }>({});

  const getTickInterval = () => {
    let days: number;
    if (groupByValue) {
      days = daysDifference(startDateTime, endDateTime);
      switch (reportData?.interval) {
        case "hour":
          if (days <= 0.3) {
            return "hour";
          } else {
            return "3 hours";
          }
        case "day":
          return "day";
        default:
          return "month";
      }
    } else {
      let highestfrequency: any = getHighestFrequency(
        startDateTime,
        endDateTime
      );

      if (highestfrequency == selectedFrequency) {
        days = daysDifference(startDateTime, endDateTime);
        switch (true) {
          case days <= 0.3:
            return "hour";
          case days <= 1:
            return "3 hours";
          case days <= 7:
            return "day";
          case days <= 31:
            return "3 days";
          default:
            return "month";
        }
      } else if (
        reportValues?.filterDuration ==
          DurationDropDownConstants.CUSTOM_VALUE &&
        selectedFrequency === FrequencyDropdown.DAY_VALUE
      ) {
        let actualDifference = daysDifference(startDateTime, endDateTime);
        switch (true) {
          case actualDifference <= 0.3:
            return "hour";
          case actualDifference <= 1:
            return "3 hours";
          case actualDifference <= 7:
            return "day";
          case actualDifference <= 30:
            return "3 days";
          case actualDifference >= 31:
            return "3 days";
          default:
            return "month";
        }
      } else {
        days = daysDifference(startPage, endPage);
        switch (true) {
          case days <= 0.3:
            return "hour";
          case days <= 1:
            return "3 hours";
          case days <= 7:
            return "day";
          case days <= 31:
            return "3 days";
          default:
            return "month";
        }
      }
    }
  };



  const getGraphData = () => {
    let graphData = [];
    if (reportData && reportData.rows) {
      graphData = extractData(reportData);
    }
    return graphData;
  };

  interface ChartData {
    data: Serie[];
    header: string;
  }

  const setGraphHeader = () => {
    if (groupByValue) {
      return "";
    }
    switch (reportData.interval) {
      case "hour":
        return getFormattedDate(startPage, "Do MMMM YYYY");
      case "day":
        if (
          getFormattedDate(startPage, "YYYY") !==
          getFormattedDate(endPage, "YYYY")
        ) {
          return (
            getFormattedDate(startPage, "MMMM YYYY") +
            " - " +
            getFormattedDate(endPage, "MMMM YYYY")
          );
        } else if (
          getFormattedDate(startPage, "MMMM") !==
          getFormattedDate(endPage, "MMMM")
        ) {
          return (
            getFormattedDate(startPage, "MMMM") +
            " - " +
            getFormattedDate(endPage, "MMMM YYYY")
          );
        } else if (
          getFormattedDate(startPage, "D") == getFormattedDate(endPage, "D")
        ) {
          return getFormattedDate(startPage, "Do MMMM YYYY");
        }
        return (
          getFormattedDate(startPage, "Do") +
          " - " +
          getFormattedDate(endPage, "Do MMMM YYYY")
        );
      case "month":
        if (
          getFormattedDate(startPage, "YYYY") !==
          getFormattedDate(endPage, "YYYY")
        ) {
          return (
            getFormattedDate(startPage, "MMMM YYYY") +
            " - " +
            getFormattedDate(endPage, "MMMM YYYY")
          );
        } else if (
          getFormattedDate(startPage, "MMMM") !==
          getFormattedDate(endPage, "MMMM")
        ) {
          return (
            getFormattedDate(startPage, "MMMM") +
            " - " +
            getFormattedDate(endPage, "MMMM YYYY")
          );
        }
        return getFormattedDate(startPage, "MMMM YYYY");
      default:
        return "";
    }
  };

  const getInsightsDateLabels = (date, format) => {
    let offset;
    if (groupByValue) {
      offset = getClientTimeZoneOffset(startDateTime);
    } else {
      offset = getClientTimeZoneOffset(startPage);
    }
    return moment(date).utcOffset(offset).format(format);
  };

  function extractData(data: ReportData) {
    let graphData: ChartData[] = [];
    let graphs: {[key: string]: ReportColumnData[]} = {};

    if (reportViewValue === reportTypes.sorting) {
      for (let row of data.rows) {
        for (let loc of row.location) {
          if (loc?.isAMHLocation) {
            data.columns.map((col) => {
              let newCol = {...col}
              const newHeader = `${loc.locationName}.${col.header}`;
              newCol.prevId = newCol.id;
              newCol.id = newHeader;
              newCol.header = newHeader;
              graphs = {...graphs, [newHeader]: [newCol]};
            });
          }
        }
        break;
      }
    } else {
      graphs = data.columns.reduce((acc, curr) => {
        if (!acc[curr.header]) acc[curr.header] = [];
        if (curr.info?.newGraph) {
          acc[curr.subHeader] = [];
          acc[curr.subHeader].push(curr);
          return acc;
        }
        if (curr.info?.hideGraph) return acc;
        acc[curr.header].push(curr);
        return acc;
      }, {});
    }
    
    for (let headerKey in graphs) {
      let eachGraphData: ChartData = {
        header: groupByValue ? headerKey : headerKey + " : " + setGraphHeader(),
        data: [],
      };

      let subCharts: { [key: string]: Serie } = graphs[headerKey].reduce(
        (acc, curr) => {
          acc[curr.id] = {
            id: curr.subHeader,
            data: [],
            key: curr.id,
            total: 0,
          };
          if (curr.info?.currency) {
            acc[curr.id]["currency"] = curr.info.currency;
          }
          return acc;
        },
        {}
      );

      if (reportViewValue === reportTypes.sorting) {
        for (let row of data.rows) {
          for (let loc of row.location) {
            if(headerKey.includes(loc.locationName)) {
              for (let graph of graphs[headerKey]) {
                subCharts[graph.id].data.push({
                  x: getInsightsDateLabels(row.date, chartTimeFormat),
                  y: loc.data[graph.prevId],
                });
                subCharts[graph.id].total += loc.data[graph.prevId];
              }
            }
          }
        }
      } else {
        for (let row of data.rows) {
          for (let graph of graphs[headerKey]) {
            subCharts[graph.id].data.push({
              x: getInsightsDateLabels(row.date, chartTimeFormat),
              y: row.data[graph.id],
            });
            subCharts[graph.id].total += row.data[graph.id];
          }
        }
      }

      eachGraphData.data = Object.keys(subCharts).map((key) => subCharts[key]);
      graphData.push(eachGraphData);
    }

    return graphData;
  }
  const onChangeReport = () => {
    setFormat(formatLookup[getTickInterval()]);
    setChartData(getGraphData());
  };

  const onDateChange = (values: any) => {
    setStartTime(values.startTime);
    setEndTime(values.endTime);
  };

  const updatePageInfo = (values: any) => {
    setStartPage(values.startPage);
    setEndPage(values.endPage);
    setStartDateTime(values.startDateTime);
    setEndDateTime(values.endDateTime);
    setSelectedFrequency(values?.selectedFrequency);
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page);
    setPageChanged(true);
  };

  useEffect(() => {
    if (prevReport) {
      reportValues.startTime && setStartTime(reportValues.startTime);
      reportValues.endTime && setEndTime(reportValues.endTime);
    }
  }, [prevReport]);

  // format lookup for various interval
  const formatLookup = {
    hour: {
      x: {
        format: "time:%H:%M",
        scale: {
          precision: "minute",
          format: "%H:%M ",
          tickValue: "every hour",
          min: startTime
            ? moment(startPage).startOf("h").format(chartTimeFormat)
            : moment(startPage).startOf("day").format(chartTimeFormat),
          max: endTime
            ? moment(endPage).endOf("h").format(chartTimeFormat)
            : moment(endPage).endOf("day").format(chartTimeFormat),
        },
      },
      y: {},
    },
    "3 hours": {
      x: {
        format: "time:%H:%M",
        scale: {
          precision: "minute",
          format: "%H:%M ",
          tickValue: "every 3 hours",
          min: startTime
            ? moment(startPage).format(chartTimeFormat)
            : moment(startPage).startOf("day").format(chartTimeFormat),
          max: endTime
            ? moment(endPage).format(chartTimeFormat)
            : moment(endPage).endOf("day").format(chartTimeFormat),
        },
      },
      y: {},
    },
    day: {
      x: {
        format: "time:%d %b",
        scale: {
          precision: "day",
          format: "%A ",
          tickValue: "every day",
          min: moment(startPage).startOf("day").format(chartTimeFormat),
          max: moment(endPage).endOf("day").format(chartTimeFormat),
        },
      },
      y: {},
    },
    "3 days": {
      x: {
        format: "time:%e %b",
        scale: {
          precision: "day",
          format: "%d",
          tickValue: "every 3 days",
          min: moment(startPage).startOf("month").format(chartTimeFormat),
          max: moment(endPage).endOf("month").format(chartTimeFormat),
        },
      },
      y: {},
    },
    month: {
      x: {
        format: "time:%B",
        scale: {
          precision: "month",
          format: "%b",
          tickValue: "every month",
          min: moment(startPage).startOf("year").format(chartTimeFormat),
          max: moment(endPage).endOf("year").format(chartTimeFormat),
        },
      },
      y: {},
    },
  };

  const insightHeaderResized = (height: number) => {
    setInsightHeaderHeight(height);
  };


  const exportGraph = (csvFileName : string) => {
    setDownloadingGraph(true);
    AppService.showToast(t(jsonData.ExportPdfAlertMessage))
    setTimeout(() => {
      graphRef.current.callChildFunction(csvFileName);
    }, 10);
  }

  return (
    <>
      <div className="pl-vw insightHeaderDiv">
        <Row id="1">
          <InsightHeader
            onChangeReport={onChangeReport}
            onDateChange={onDateChange}
            activeRepresentation={activeRepresentation}
            setActiveRepresentation={setActiveRepresentation}
            onInsightHeaderResize={insightHeaderResized}
            groupByValue={groupByValue}
            setGroupByValue={setGroupByValue}
            setShowLoading={setShowLoading}
            updatePageInfo={updatePageInfo}
            pageChanged={pageChanged}
            setPageChanged={setPageChanged}
            onPageChange={onPageChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pages={pages}
            setPages={setPages}
            setExportData={setExportData}
            breakFrom={breakFrom}
            breakTo={breakTo}
            setBreakFrom={setBreakFrom}
            setBreakTo={setBreakTo}
            selectedLocations={locations}
            setSelectedLocations={setLocations}
            selectedDevices={devices}
            setSelectedDevices={setDevices}
            viewInsight={location?.state?.Viewable ?? false}
            onlyCloudCheck={onlyCloudCheck}
            setOnlyCloudCheck={setOnlyCloudCheck}
            insighthasData={insighthasData}
            setInisghtReportValue={setInisghtReportValue}
            setReportViewValue={setReportViewValue}
            exportGraph={exportGraph}
            downloadingGraph={downloadingGraph}
            reportViewValue={reportViewValue}
          />
        </Row>
      </div>
      {/* chartData={translateGraphText(chartData,t,'header','id')} */}
      <ReportScreen>
        {activeRepresentation === InsightRepresentation.GRAPHICAL ? (
          <>
          <InsightGraph
            graphType={reportViewValue === reportTypes.sorting ? reportTypes.sorting : ""}
            chartData={chartData}
            colorKeys={getCoumnsKeys(reportData)}
            pieChartData={convertForGraphData(reportData?.rows)}
            groupByValue={groupByValue}
            format={format}
            startPage={startPage}
            endPage={endPage}
            pages={pages}
            onlyCloudCheck={onlyCloudCheck}
            selectedDevices={devices}
            setInsightHasData={setInsightHasData}
            ref={graphRef}
            setDownloadingGraph={setDownloadingGraph}
            />
          </>
        ) : (
          (reportViewValue === reportTypes.paymentDetails ?
            <PaymentDetailTable
              showLoading={showLoading}
              groupByValue={groupByValue}
              insightHeaderHeight={insightHeaderHeight}
              startPage={startPage}
              endPage={endPage}
              pages={pages}
              exportData={exportData}
              setExportData={setExportData}
              setBreakFrom={setBreakFrom}
              setBreakTo={setBreakTo}
              selectedLocations={locations}
              selectedDevices={devices}
              getInsightsDateLabels={getInsightsDateLabels}
              onlyCloudCheck={onlyCloudCheck}
              setInsightHasData={setInsightHasData}
              inisghtReportValue={inisghtReportValue}
            />
          :
            <InsightTable
              showLoading={showLoading}
              groupByValue={groupByValue}
              insightHeaderHeight={insightHeaderHeight}
              startPage={startPage}
              endPage={endPage}
              pages={pages}
              exportData={exportData}
              setExportData={setExportData}
              setBreakFrom={setBreakFrom}
              setBreakTo={setBreakTo}
              selectedLocations={locations}
              selectedDevices={devices}
              getInsightsDateLabels={getInsightsDateLabels}
              onlyCloudCheck={onlyCloudCheck}
              setInsightHasData={setInsightHasData}
              inisghtReportValue={inisghtReportValue}
            />
          )
        )}
        {!reportDataFormState?.loading &&
          !formState?.loading &&
          !showLoading &&
          pages * 10 > 10 &&
          !groupByValue && (
            <AntdPagination
              className="insight-pagination"
              total={pages * 10}
              current={currentPage}
              defaultPageSize={10}
              onChange={(page: any, pageSize: any) => onPageChange(page)}
            />
          )}
        {
          !reportDataFormState?.loading && !formState?.loading  && !showLoading && !insighthasData ?
            <div className="MessageInisght">
              {t(jsonData.NoInsight)}
            </div>
            : null}
      </ReportScreen>
    </>
  );
};

export default Insights;
