import React, { useState, useMemo, useEffect } from "react";
import { Modal, Table, Button, Input, Row, Col, Tooltip, Form } from "antd";
import { Button as CustomButton } from "../../../../../bit_components/common/button";
import { FORBIDDEN_STATUS, jsonData, ScheduleWorkItemModal } from "../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkItemLcd,
  fetchWorkItemDevices,
  storeWorkItem,
} from "../../../../../redux/actions/workItems/workAction";
import { cameFromOrganisation } from "../../../../../utility/appUtil";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  WorkSelector,
  clearWorkItemDeviceListFormState,
  clearCreatedWorkItemLcdFormState,
} from "../../../../../redux/reducers/workItems/workReducer";
import "./styles.less";
import {
  fetchDevicesHealthByOrgId,
  getDevicesStatus,
} from "../../../../../redux/actions/device/deviceAction";
import { DeviceSelector } from "../../../../../redux/reducers/device/deviceReducer";
import { getStatusTypeEllipse } from "../../../../../utility/utils";
import WarnModal from "../../../stateless/common/warnModal";
import { Custominput } from "../../../../../bit_components/common/custominput";
import DeviceService from "../../../../services/device/deviceService";
import { AppService } from "../../../../services/ui/appService";

const workItemColumns = [
  {
    dataIndex: "description",
    key: "description",
    render: (text) => <span>{text}</span>,
  },
];

const WorkItemSelectionModal = ({
  openModal,
  onClose,
  onNext,
  orgSupportedWorkItemList,
  selectedWorkItem,
  setSelectedWorkItem,
  organisationId,
  orgSupportedWorkItemListFormState,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredWorkItems = useMemo(
    () =>
      orgSupportedWorkItemList.filter((item) =>
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [searchTerm, orgSupportedWorkItemList]
  );

  const handleSelectionChange = (selectedRowKeys) => {
    if (selectedRowKeys.length > 1) {
      selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
    }
    const selectedItem = filteredWorkItems.find(
      (item) => item?.id === selectedRowKeys[0]
    );

    if (selectedItem && selectedItem?.id !== selectedWorkItem?.id) {
      setSelectedWorkItem(selectedItem);
      const parameters = {
        organisationId: organisationId,
        body: {
          applicationVersionIds: selectedItem?.applicationVersionIds,
        },
      };
      dispatch(fetchWorkItemDevices(parameters));

    } else {
      setSelectedWorkItem(null);
    }
  };

  const handleRowClick = (record) => {
    if (record?.id === selectedWorkItem?.id) {
      setSelectedWorkItem(null);
    } else {
      setSelectedWorkItem(record);
      const parameters = {
        organisationId: organisationId,
        body: {
          applicationVersionIds: record?.applicationVersionIds,
        },
      };
      dispatch(fetchWorkItemDevices(parameters));
    }

  };

  return (
    <Modal
      title={t(jsonData.SelectWorkItem)}
      visible={openModal}
      closable={false}
      className="create-workitem-modals"
      footer={[
        <>
          <Row justify="center">
            <Col>
            <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={!selectedWorkItem ? "" : t(jsonData.Next)}
              >
              <span>
              <Button
                key="next"
                type="primary"
                disabled={!selectedWorkItem}
                className="create-workitem-action-button"
                onClick={() => onNext(selectedWorkItem)}
              >
                {t(jsonData.Next)}
              </Button>
              </span>
              </Tooltip>
          
            </Col>
          </Row>
          <Row justify="center">
            <Col>
            <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={t(jsonData.Cancel)}
              >
              <Button
                key="cancel"
                onClick={onClose}
                className="create-workitem-cancel-button"
              >
                {t(jsonData.Cancel)}
              </Button>
              </Tooltip>
            </Col>
          </Row>
        </>
      ]}
    >
      <Input
        placeholder={t(jsonData.SearchWorkItem)}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: "16px" }}
      />
      {searchTerm && !filteredWorkItems?.length && (
        <p
          className="not-found-text"
        >
          {t(jsonData.NoDataFound)}
        </p>
      )}
      <Table
        dataSource={
          orgSupportedWorkItemListFormState?.loading ? [] : filteredWorkItems
        }
        columns={workItemColumns}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedWorkItem ? [selectedWorkItem?.id] : [],
          onChange: handleSelectionChange,
          renderCell: (checked, record, index, originNode) => (
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {originNode}
            </span>
          ),
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={false}
        showHeader={false}
        scroll={{ y: 300 }}
        className="create-workitem-table"
      />
      {orgSupportedWorkItemListFormState?.loading && (
        <div className="loaderWrapper">
          <div className="loaderBody">
            <span className="spinner"></span>
            <span className="loadingText">{t(jsonData.Loading)}.</span>
          </div>
        </div>
      )}
    </Modal>
  );
};

const deviceColumns = (t, isSelectionDisabled) => [
  {
    title: t(jsonData.Status),
    dataIndex: "status",
    key: "status",
    render: (text: string) => {
      return (
        <Tooltip
          mouseLeaveDelay={0}
          overlayClassName="dashBoardTooltip"
          placement="bottom"
          title={text}
        >
          <span>
            <img
              src={getStatusTypeEllipse(text?.toString().toLowerCase())}
              alt={text}
            />
            &nbsp;{text}
          </span>
        </Tooltip>
      );
    },
  },
  {
    title: t(jsonData.Location),
    dataIndex: "location",
    key: "location",
    render: (text) => (
      <Tooltip
        mouseLeaveDelay={0}
        overlayClassName="dashBoardTooltip"
        placement="bottom"
        title={text}
      >
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: t(jsonData.DeviceName),
    dataIndex: "deviceName",
    key: "deviceName",
    render: (text) => (
      <Tooltip
        mouseLeaveDelay={0}
        overlayClassName="dashBoardTooltip"
        placement="bottom"
        title={text}
      >
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: t(jsonData.Application),
    dataIndex: "application",
    key: "application",
    render: (text) => (
      <Tooltip
        mouseLeaveDelay={0}
        overlayClassName="dashBoardTooltip"
        placement="bottom"
        title={text}
      >
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: t(jsonData.Note),
    dataIndex: "note",
    key: "note",
    render: (_, record) => {
      const { warningText } = isSelectionDisabled(record);
      return (
        <Tooltip
          mouseLeaveDelay={0}
          overlayClassName="dashBoardTooltip"
          placement="bottom"
          title={warningText}
        >
          <span>{warningText}</span>
        </Tooltip>
      );
    },
  },
];

const DeviceSelectionModal = ({
  openModal,
  onClose,
  onSave,
  availableDevices,
  deviceStatusMap,
  isWorkItemDeviceLoading,
  selectedDevices,
  setSelectedDevices,
  saveButtonLoading,
  setShowWarn
}) => {
  const { t } = useTranslation();

  const isSelectionDisabled = (device) => {
    const currentDeviceStatus = deviceStatusMap[device.lcdDeviceId];
    const isForbidden = FORBIDDEN_STATUS.includes(device?.status);
    const isLockedByUser = currentDeviceStatus?.LockState?.Locked;
    const isWorkItemProcessing = currentDeviceStatus?.ProcessingWorkItem;

    let warningText = "";
    if (isForbidden) {
      warningText = t(jsonData.ForbiddenStatusWarnMessage);
    }
    if (isLockedByUser) {
      warningText += ` ${t(jsonData.DeviceLocked)} ${currentDeviceStatus?.LockState?.LockedByUsername}`;
    }
    if (isWorkItemProcessing) {
      warningText += ` ${t(jsonData.ConfigurationInProgress)}`;
    }

    return {
      isDisabled: isForbidden || isLockedByUser || isWorkItemProcessing,
      warningText,
    };
  };

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedDevices(selectedRows);
  };

  return (
      <Modal
      title={t(jsonData.SelectDevice)}
      visible={openModal}
      closable={false}
      className="create-workitem-modals"
      footer={[
        <Row
          justify="center"
          className="modal-footer-buttons"
          gutter={[16, 16]}
        >
          <Col xs={24} sm={8} md={6}>
            {saveButtonLoading ? (
              <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={t(jsonData.Saving)}
              >
                <span>
                  <CustomButton type="smallBlack create-workitem-saving-btn">
                    <div className="loaderButton">
                      <span className="spinner"></span>
                    </div>
                    {t(jsonData.Saving)}
                  </CustomButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={!selectedDevices.length ? "" : t(jsonData.Save)}
              >
                <span>
                  <Button
                    key="save"
                    type="primary"
                    disabled={!selectedDevices.length}
                    className="create-workitem-action-button"
                    onClick={() => onSave(selectedDevices)}
                  >
                    {t(jsonData.Save)}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Tooltip
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={!selectedDevices.length ? "" : t(jsonData.Schedule)}
            >
              <span>
                <Button
                  key="schedule"
                  type="primary"
                  disabled={!selectedDevices.length}
                  className="create-workitem-action-button"
                  onClick={()=> {
                    setShowWarn((prev) => !prev)
                  }}
                >
                  {t(jsonData.Schedule)}
                </Button>
              </span>
            </Tooltip>
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Tooltip
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={t(jsonData.Cancel)}
            >
              <span>
                <Button
                  key="cancel"
                  onClick={onClose}
                  className="create-workitem-cancel-button"
                >
                  {t(jsonData.Cancel)}
                </Button>
              </span>
            </Tooltip>
          </Col>
        </Row>
      ]}
    >
      <Table
        dataSource={isWorkItemDeviceLoading ? [] : availableDevices}
        columns={deviceColumns(t, isSelectionDisabled)}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedDevices.map((item) => item.id),
          onChange: handleSelectionChange,
          getCheckboxProps: (record) => {
            const { isDisabled, warningText } = isSelectionDisabled(record);
            return {
              disabled: isDisabled,
              title: warningText,
            };
          },
        }}
        pagination={false}
        scroll={{ x: 1000, y: 300 }}
        className="create-workitem-table"
      />
      {isWorkItemDeviceLoading && (
        <div className="loaderWrapper">
          <div className="loaderBody">
            <span className="spinner"></span>
            <span className="loadingText">{t(jsonData.Loading)}.</span>
          </div>
        </div>
      )}
    </Modal>
  )
};

const CreateWorkItemModal = (props) => {
  const [selectedWorkItem, setSelectedWorkItem] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [isWorkItemDeviceLoading, setIsWorkItemDeviceLoading] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [showWarn, setShowWarn] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedOrganisation } = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const {
    workItemDeviceList,
    workItemDeviceListFormState,
    orgSupportedWorkItemListFormState,
    createdWorkItemLcdFromSate,
  } = useSelector(WorkSelector);
  const {
    devicesHealth,
    deviceStatusMap,
    devicesStatusFormState,
    devicesHealthFormstate,
  } = useSelector(DeviceSelector);

  const {
    orgSupportedWorkItemList,
    isWorkItemModalOpen,
    setIsWorkItemModalOpen,
    setCreateWorkItemOpen,
  } = props;

  const getOrgId = useMemo(() => {
    let orgId = appUser?.organization.id;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }, [appUser, selectedOrganisation]);

  const devices = useMemo(() => {
    const flatDevices = workItemDeviceList.flatMap((workItemDevice: any) => {
      return workItemDevice?.devices.map((device: any) => {
        const matchingHealth = devicesHealth.find(
          (health: any) => health?.deviceId === device?.id
        );
        return {
          id: device?.id,
          lcdDeviceId: device?.lcDeviceId,
          status: matchingHealth?.statusType,
          location: matchingHealth?.hierarchyName,
          deviceName: matchingHealth?.deviceName,
          application: `${workItemDevice?.applicationName}-${workItemDevice?.applicationVersion}`,
        };
      });
    });
  
    return flatDevices.sort((a, b) =>
      a.deviceName?.localeCompare(b.deviceName, undefined, { sensitivity: "base" })
    );

  }, [workItemDeviceList, devicesHealth]);

  useEffect(() => {
    if (
      devicesStatusFormState?.loading ||
      devicesStatusFormState?.loading ||
      workItemDeviceListFormState?.loading
    ) {
      setIsWorkItemDeviceLoading(true);
    } else {
      setIsWorkItemDeviceLoading(false);
    }
  }, [
    devicesHealthFormstate,
    devicesStatusFormState,
    workItemDeviceListFormState,
  ]);

  useEffect(() => {
    if (createdWorkItemLcdFromSate?.isSuccess && selectedDevices.length) {
      const lcdDeviceIds = selectedDevices.map((device) => device?.lcdDeviceId);
      const storeWorkItemBody = {
        userName: appUser?.userName,
        deviceIds: lcdDeviceIds,
        userId: appUser?.id,
      };
      dispatch(storeWorkItem(storeWorkItemBody));
      setSelectedWorkItem(null);
      setSelectedDevices([]);
      setAvailableDevices([]);
      setSaveButtonLoading(false);
      setIsDeviceModalOpen(false);
      dispatch(clearCreatedWorkItemLcdFormState());
      dispatch(clearWorkItemDeviceListFormState());
    }
    else if (createdWorkItemLcdFromSate?.isError) {
      setSaveButtonLoading(false);
    }
  }, [createdWorkItemLcdFromSate]);

  const handleWorkItemNext = (selectedWorkItem) => {
    if (!selectedWorkItem) return;

    setAvailableDevices(devices);
    dispatch(fetchDevicesHealthByOrgId({ organizationId: getOrgId }));
    dispatch(
      getDevicesStatus({
        userName: appUser?.userName,
        deviceIdList: devices.map((dev) => dev?.lcdDeviceId),
      })
    );
    setIsWorkItemModalOpen(false);
    setIsDeviceModalOpen(true);
  };
  

  const checkForbiddenDevices = async (deviceIdList: string[]) => {
    try {
      const deviceService = new DeviceService();
      const deviceStatusMap = await deviceService.getDevicesStatus({
        deviceIdList,
        userName: appUser?.userName,
      });
  
      return Object.values(deviceStatusMap).some((currentStatus:any) => 
        currentStatus?.ProcessingWorkItem || currentStatus?.LockState?.Locked
      );
    } catch (error) {
      console.error("Error in fetching device status", error);
    }
  };
  

  const handleDeviceSave = async (selectedDevices) => {
    setSaveButtonLoading(true);
    const lcdDeviceIds = selectedDevices.map((device) => device?.lcdDeviceId);
    const isAnyDeviceForbidden = await checkForbiddenDevices(lcdDeviceIds);
    
    if (isAnyDeviceForbidden) {
      AppService.showToast(t(jsonData.CanNotCreateWorkItem), true);
      return true;
    }
    else {
      const createWorkItemBody = {
        body: {
          DeviceIds: lcdDeviceIds,
          WorkItemTypeId: selectedWorkItem?.lcdWorkItemTypeId,
        },
        userName: appUser?.userName,
      };
      dispatch(createWorkItemLcd(createWorkItemBody));
    }  
  };

  const onClose = () => {
    setIsWorkItemModalOpen(false);
    setIsDeviceModalOpen(false);
    setSelectedWorkItem(null);
    setAvailableDevices([]);
    setCreateWorkItemOpen(false);
    dispatch(clearWorkItemDeviceListFormState());
    dispatch(clearCreatedWorkItemLcdFormState());
  };

  return (
    <>
      {isWorkItemModalOpen && (
        <WorkItemSelectionModal
          openModal={isWorkItemModalOpen}
          onClose={onClose}
          onNext={handleWorkItemNext}
          orgSupportedWorkItemList={orgSupportedWorkItemList}
          selectedWorkItem={selectedWorkItem}
          setSelectedWorkItem={setSelectedWorkItem}
          organisationId={getOrgId}
          orgSupportedWorkItemListFormState={orgSupportedWorkItemListFormState}
        />
      )}
      {isDeviceModalOpen && (
        <DeviceSelectionModal
          openModal={isDeviceModalOpen}
          onClose={onClose}
          onSave={handleDeviceSave}
          availableDevices={availableDevices}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          deviceStatusMap={deviceStatusMap}
          isWorkItemDeviceLoading={isWorkItemDeviceLoading}
          saveButtonLoading={saveButtonLoading}
          setShowWarn={setShowWarn}
        />
      )}
      {
      showWarn && <WarnModal
        cancelButton={t(ScheduleWorkItemModal.CANCEL_BTN_TEXT)}
        confirmButton={t(ScheduleWorkItemModal.CONFIRM_BTN_TEXT)}
        primaryText={t(ScheduleWorkItemModal.TITLE)}
        disabled={false}
        secondaryText={ false ? null : <Form form={form} >
          <Form.Item name="name" >
            <Custominput
              customLabelClass="labelName formControl mt-20"
              name={t(jsonData.Name)}
              labelName="name"
              placeholder={t(jsonData.Name)}
              rules={[
                {
                  required: true,
                  message: t(jsonData.PleaseMentionName)
                },
                {
                  max: 100,
                  message: t(jsonData.LimitExceeded100characters)
                }
              ]}
              onChange={()=>{}}
            />
          </Form.Item>
        </Form>}
        isModalVisible={showWarn}
        cancelCallback={() => {
          setShowWarn((prev) => !prev)
          form.resetFields()
        }}
        confirmCallback={() => {
          setShowWarn((prev) => !prev)
          form.resetFields()
        }}
        hideOkText={false}
      />
      }
    </>
  );
};

export default CreateWorkItemModal;