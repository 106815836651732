import { useEffect, useState } from "react";
import "./styles.less";
import { Modal } from "../../../../../../../bit_components/ui/modal";
import { useTranslation } from "react-i18next";
import { jsonData } from "../../../../../../constants/constants";
import { Col, Popover, Row, Tooltip } from "antd";
import { AntdInfinityTable } from "../../../../../stateless/common/antdTable/antdInfininityTable";
import CopyComponent from "../../../../../stateless/common/copyComponent";
import "../../deviceConfigBody/styles.less"
import {
  DeviceSelector,
  clearCopiedGroups,
  clearCopiedItems,
  removeChangedGroups,
  removeChangedItems,
  removeCopiedItems,
  setRemovedGroups,
  setStoredSettings,
  removeReorderedGroups,
  removeCopiedGroups,
  clearStoreConfigFormState,
  setNewlyAddedGroup,
  removeNewDeviceAssets,
  removeExistingDeviceAssets,
  setRestartDevice,
} from "../../../../../../../redux/reducers/device/deviceReducer";
import {
  storeConfiguration as storeConfigurationAction,
  copyConfiguration as copyConfigurationAction,
  storeDeviceAssets,
} from "../../../../../../../redux/actions/device/deviceAction";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIconImg } from "../../../../../../images";
import { InfoCircleOutlined } from "@ant-design/icons";
import DevicesList from "./DevicesList";
import {
  clearCache,
  deepCopy,
  handleEnterKeyPress,
  removeEmptyValues,
  removeKeys,
} from "../../../../../../../utility/utils";
import { Custominput } from "../../../../../../../bit_components/common/custominput";
import { userStateSelector } from "../../../../../../../redux/reducers/user/userReducer";
import { storeWorkItem } from "../../../../../../../redux/actions/workItems/workAction";
import { AppService } from "../../../../../../services/ui/appService";
import DeviceService from "../../../../../../services/device/deviceService";

const checkForbiddenDevice = async (
  configDevices: any[],
  userName: string,
  checkedList: string[]
) => {
  if (configDevices?.length > 0) {
    const deviceIdList = configDevices.map((item: any) => {
      return item.DeviceId;
    });

    try {
      const deviceService = new DeviceService();
      let deviceStatusMap = await deviceService.getDevicesStatus({
        deviceIdList: deviceIdList,
        userName: userName,
      });

      const isAnyDeviceForbidden = checkedList.some((item) => {
        const currentStatus = deviceStatusMap[item];
        return (
          currentStatus &&
          (currentStatus.ProcessingWorkItem || currentStatus.LockState.Locked)
        );
      });

      if (isAnyDeviceForbidden) {
        return true;
      }
    } catch (err) {
      console.log("Error in fetching device status", err);
    }
  }
};

const PendingChangesTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    openModal,
    setOpenModal,
    getNodeContent,
    deviceID,
    setCheckedList,
  } = props;

  const {
    removedGroups,
    changedGroups,
    changedItems,
    storedSettings,
    newlyAddedGroups
  } = useSelector(DeviceSelector);

  const [tableData, setTableData] = useState([]);

  const discardStoredItem = (data) => {
    let response = removeKeys(deepCopy(storedSettings), data.nodeId);
    let newStoredSettings = removeEmptyValues(response);

    if (data.isGroup) {
      dispatch(removeChangedGroups(data.nodeId));
      dispatch(removeReorderedGroups(data.nodeId));

      let newRemovedGroupIds = removedGroups.filter(
        (group) => !group.includes(data.nodeId)
      );
      dispatch(setRemovedGroups(newRemovedGroupIds));

      newlyAddedGroups.map((item) => {
        dispatch(removeChangedItems(item?.FullNodeId));
        let response = removeKeys(newStoredSettings, item?.FullNodeId);
        newStoredSettings = removeEmptyValues(response);
      });

      const newGroupsCopy = deepCopy(newlyAddedGroups);
      const filteredGroups = newGroupsCopy.filter((group : any) => !group?.FullNodeId?.includes(data.nodeId));
      dispatch(setNewlyAddedGroup(filteredGroups));
    } else {
      dispatch(removeChangedItems(data?.nodeId));
      dispatch(removeNewDeviceAssets(data?.nodeId));
      dispatch(removeExistingDeviceAssets(data?.nodeId));
    }
    dispatch(setStoredSettings({ ...newStoredSettings }));
  };

  const setSaveData = () => {
    const saveData = Object.values(changedItems)?.map(
      (item: any, index: number) => ({
        key: index,
        description: item?.Description ?? item?.FullNodeId,
        currentValue: item?.Data,
        newValue: item?.newData.toString(),
        nodeId: item?.FullNodeId,
        path: item?.path,
        isGroup: false,
      })
    );

    const groupsData = Object.keys(changedGroups)?.map(
      (item: any, index: number) => ({
        key: index + Object.values(changedItems).length,
        description: item,
        currentValue: item,
        newValue: item,
        nodeId: item,
        path: item?.path,
        isGroup: true,
      })
    );
    return [...saveData, ...groupsData];
  };

  const columns = [
    {
      title: `${t(jsonData.Description)}`,
      key: `${t(jsonData.Description)}`,
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: `${t(jsonData.CurrentValue)}`,
      key: `${t(jsonData.CurrentValue)}`,
      dataIndex: "currentValue",
      ellipsis: true,
    },
    {
      title: `${t(jsonData.NewValue)}`,
      key: `${t(jsonData.NewValue)}`,
      dataIndex: "newValue",
      ellipsis: true,
    },
    {
      title: "",
      key: `${t(jsonData.information)}`,
      render(information) {
        return (
          <Popover
            trigger={["click", "focus"]}
            placement="leftBottom"
            content={
              <div className="node-id-container">
              <div className="propery-node-id ant-input ant-input-disabled">
                <Tooltip
                  mouseLeaveDelay={0}
                  overlayClassName="dashBoardTooltip"
                  placement="bottom"
                  title={t(jsonData.NodeId)}
                >
                  <span className="folder-items">{information?.nodeId}</span>
                </Tooltip>
              </div>
              <span className="node-id-copy-button"><CopyComponent text={information?.nodeId}/></span>
              </div>
            }
          >
            <InfoCircleOutlined className="copy-modal-info" tabIndex={0} />
          </Popover>
        );
      },
    },
    {
      title: "",
      key: `${t(jsonData.Discard)}`,
      render(data: any) {
        return (
          <Tooltip
            mouseLeaveDelay={0}
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.Discard)}
          >
            <img
              tabIndex={0}
              className="copy-modal-discard"
              src={DeleteIconImg}
              alt="delete"
              onClick={() => discardStoredItem(data)}
              onKeyDown={(e: any) =>
                handleEnterKeyPress(e, () => discardStoredItem(data))
              }
            />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (openModal) {
      if (
        Object.keys(changedItems)?.length === 0 &&
        Object.keys(changedGroups)?.length === 0
      ) {
        getNodeContent();
        setOpenModal(false);
      } else {
        setTableData(setSaveData());
      }
    } else {
      setCheckedList([deviceID]);
    }
  }, [openModal, changedItems, changedGroups]);

  return (
    <AntdInfinityTable
      tableClassName="copy-modal-table"
      pagination={false}
      scroll={{
        y: 250,
        scrollToFirstRowOnChange: true,
      }}
      dataSource={tableData}
      columns={columns}
    />
  );
};

const CopyModal = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    openCopyModal,
    setOpenCopyModal,
    copiedItems,
    showDeviceList,
    setShowDeviceList,
    deviceID,
    handleNavigate,
    copiedGroups,
  } = props;

  const { configDevices, restartDevice } = useSelector(DeviceSelector);
  const { appUser } = useSelector(userStateSelector);

  const [tableData, setTableData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [confirming, setConfirming] = useState<boolean>(false);

  const handleCancel = () => {
    setCheckedList([]);
    dispatch(clearCopiedGroups());
    dispatch(clearCopiedItems());
    setOpenCopyModal(false);
    handleNavigate();
  };

  const handleOk = async () => {
    if (showDeviceList) {
      setConfirming(true)
      const isAnyDeviceForbidden = await checkForbiddenDevice(
        configDevices,
        appUser.userName,
        checkedList
      );
      setConfirming(false);

      if (isAnyDeviceForbidden) {
        AppService.showToast(t(jsonData.CannotUpdateConfiguration), true);
        return true;
      }

      const payload = {
        TargetDeviceIds: checkedList,
        NodeIds: Object.keys(copiedItems),
        GroupIds: Object.keys(copiedGroups)
      };

      dispatch(copyConfigurationAction({ userName: appUser.userName, deviceId: deviceID, payload }));

      let storeWorkItemBody:any = {
        deviceIds: checkedList,
        userName: appUser?.userName,
        userId: appUser?.id,
        restartDevice
      }

      setTimeout(() => {
        dispatch(storeWorkItem(storeWorkItemBody));
        if (restartDevice) {
          dispatch(setRestartDevice(false))
        }
      }, 1500)
      handleCancel();
      handleNavigate();
    } else {
      setShowDeviceList(true);
    }
  };

  const discardCopyItem = (data) => {
    dispatch(removeCopiedItems(data?.nodeId));
    dispatch(removeCopiedGroups(data?.nodeId));
  };

  const fixedCopyColumns = [
    {
      title: `${t(jsonData.Description)}`,
      key: `${t(jsonData.Description)}`,
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: `${t(jsonData.CurrentValue)}`,
      key: `${t(jsonData.CurrentValue)}`,
      dataIndex: "currentValue",
      ellipsis: true,
    },
    {
      title: "",
      key: `${t(jsonData.information)}`,
      render(information) {
        return (
          <Popover
            trigger={["click", "focus"]}
            placement="leftBottom"
            content={
              <div className="node-id-container">
                <div className="propery-node-id ant-input ant-input-disabled">
                  <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName="dashBoardTooltip"
                    placement="bottom"
                    title={t(jsonData.NodeId)}
                  >
                  <span className="folder-items">{information?.nodeId}</span>
                  </Tooltip>
                </div>
                <span className="node-id-copy-button"><CopyComponent text={information?.nodeId}/></span>
              </div>
            }
          >
            <InfoCircleOutlined className="copy-modal-info" tabIndex={0} />
          </Popover>
        );
      },
    },
    {
      title: "",
      key: `${t(jsonData.Discard)}`,
      render(data: any) {
        return (
          <Tooltip
            mouseLeaveDelay={0}
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.Discard)}
          >
            <img
              tabIndex={0}
              className="copy-modal-discard"
              src={DeleteIconImg}
              alt="delete"
              onClick={() => discardCopyItem(data)}
              onKeyDown={(e: any) =>
                handleEnterKeyPress(e, () => discardCopyItem(data))
              }
            />
          </Tooltip>
        );
      },
    },
  ];

  const setCopyData = () => {
    const copyNodes = Object.values(copiedItems)?.map(
      (item: any, index: number) => ({
        key: index + 1,
        description: item?.Description ?? item?.FullNodeId,
        currentValue: item?.Data,
        nodeId: item?.FullNodeId,
      })
    );

    let copyNodesLength = copyNodes?.length ?? 0;
    const copyGroups = Object.values(copiedGroups)?.map((item: any) => ({
      key: copyNodesLength + 1,
      description: item,
      currentValue: item,
      nodeId: item,
    }));

    const copyData = [...copyNodes, ...copyGroups];
    return copyData;
  };

  useEffect(() => {
    if (openCopyModal) {
      if (
        Object.keys(copiedItems)?.length === 0 &&
        Object.keys(copiedGroups)?.length === 0
      ) {
        setOpenCopyModal(false);
      } else {
        setTableData(setCopyData());
      }
    } else {
      setCheckedList([]);
    }
  }, [openCopyModal, copiedItems, copiedGroups]);

  return (
    <Modal
      isVisible={openCopyModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      okText={showDeviceList ? confirming ? t(jsonData.Copying) : t(jsonData.Copy) : t(jsonData.Next)}
      haveInteractiveElement={true}
      primaryButtonDisabled={showDeviceList && checkedList?.length === 0}
      confirming={confirming}
    >
      {showDeviceList ? (
        <DevicesList
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          deviceId={deviceID}
          isCopyModal={true}
        />
      ) : (
        <>
          <Row className="device-popup-header">
            <Col className="device-popup-heading">
              <div>{t(jsonData.CopyDeviceConfiguration)}</div>
            </Col>
          </Row>
          <AntdInfinityTable
            tableClassName="copy-modal-table"
            pagination={false}
            scroll={{
              y: 250,
              scrollToFirstRowOnChange: true,
            }}
            dataSource={tableData}
            columns={fixedCopyColumns}
          />
        </>
      )}
    </Modal>
  );
};

export default CopyModal;

export const PendingChangesModal = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    openSaveModal,
    setOpenSaveModal,
    getNodeContent,
    showDeviceList,
    setShowDeviceList,
    deviceID,
    handleNavigate,
    discardAll,
    callStatusApi,
    getConfigViews,
    getDeviceAssets
  } = props;

  const {
    removedGroups,
    newlyAddedGroups,
    reorderedGroups,
    configDevices,
    changedItems,
    changedGroups,
    devicesStatusFormState,
    storeConfigurationFormState,
    newDeviceAssets,
    restartDevice
  } = useSelector(DeviceSelector);
  const { appUser } = useSelector(userStateSelector);

  const [checkedList, setCheckedList] = useState([deviceID]);
  const [reasonText, setReasonText] = useState<string>("");
  const [confirming, setConfirming] = useState<boolean>(false);

  const handleCancel = () => {
    setCheckedList([deviceID]);
    getNodeContent();
    setOpenSaveModal(false);
    handleNavigate();
  };

  const storeConfiguration = async () => {
    setConfirming(true);
    const isAnyDeviceForbidden = await checkForbiddenDevice(
      configDevices,
      appUser.userName,
      checkedList
    );

    if (isAnyDeviceForbidden) {
      AppService.showToast(t(jsonData.CannotUpdateConfiguration), true);
      return true;
    }
    
    const checkAssets: any = Object.values(newDeviceAssets);
    if(checkAssets.length > 0){
      let assetPayload = {};
      checkAssets.map((asset: any) => {
        assetPayload = {...assetPayload, [asset.name]: asset.file} 
      });

      const finalPayload = {
        deviceId: deviceID,
        payload: assetPayload
      };

      dispatch(storeDeviceAssets(finalPayload));
    }

    let updatedGroupOrder = [], updatedGroupIds = new Set();
    let nodes: any = Object.values(changedItems)?.map((item: any) => {
      if(item?.groupId?.length){
        updatedGroupIds.add(item.groupId)
      }
      return {
        FullNodeId: item.FullNodeId,
        Type: item?.Type,
        Data: item?.newData,
        Secured: item?.Secured,
        DeviceXmlVersions: item?.DeviceXmlVersions
      }
    });

    let groupNodes = Object.values(newlyAddedGroups)?.filter((item: any) => !changedItems?.hasOwnProperty(item?.FullNodeId));
    nodes = [...nodes, ...groupNodes];

    updatedGroupOrder = Object.values(reorderedGroups);

    Object.keys(changedGroups)?.map((item: any) => {
      updatedGroupIds.add(item)
    })
    const payload = {
      DeviceIds: checkedList,
      Nodes: nodes,
      RemovedGroupIds: removedGroups,
      UpdatedGroupOrder: updatedGroupOrder,
      UpdatedGroupIds: [...updatedGroupIds.values()],
      ScheduledDate: null
    };
    dispatch(storeConfigurationAction(payload));
    handleNavigate();
  };

  const handleOk = () => {
    if (showDeviceList) {
      storeConfiguration();
    } else {
      setShowDeviceList(true);
    }
  };

  useEffect(() => {
    if (
      storeConfigurationFormState.isSuccess ||
      storeConfigurationFormState.isError
    ) {

      let storeWorkItemBody:any = {
        deviceIds: checkedList,
        userName: appUser?.userName,
        userId: appUser?.id,
        restartDevice
      }
      
      dispatch(
        storeWorkItem(storeWorkItemBody)
      );

      if (restartDevice) {
        dispatch(setRestartDevice(false))
      }

      callStatusApi();
      setConfirming(false);
      setOpenSaveModal(false);
      discardAll();
      dispatch(clearStoreConfigFormState());
      clearCache("nodeMenus");
      clearCache("configViews");
      getConfigViews();
      getDeviceAssets();
    }
  }, [storeConfigurationFormState]);

  return (
    <Modal
      isVisible={openSaveModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      okText={showDeviceList ? confirming ? t(jsonData.Saving) : t(jsonData.Save)  : t(jsonData.Next)}
      haveInteractiveElement={true}
      primaryButtonDisabled={showDeviceList && devicesStatusFormState?.loading}
      confirming={confirming}
    >
      {showDeviceList ? (
        <DevicesList
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          deviceId={deviceID}
        />
      ) : (

        <>
          <Row className="device-popup-header">
            <Col className="device-popup-heading">
              <span className="storeDevice">{t(jsonData.StoreDeviceConfiguration)}</span>
            </Col>
            <Col span={24} className="device-popup-subHeader">
              <Custominput
                value={reasonText}
                customLabelClass="reason-for-change"
                onChange={(e: any) => setReasonText(e.target.value)}
                // Multilanguage
                placeholder={t(jsonData.ReasonForChange)}
                name="inputField"
              />
            </Col>
          </Row>
          <PendingChangesTable
            setCheckedList={setCheckedList}
            openModal={openSaveModal}
            setOpenModal={setOpenSaveModal}
            getNodeContent={getNodeContent}
            deviceID={deviceID}
          />
        </>
      )}
    </Modal>
  );
};

export const DiscardModal = (props: any) => {
  const { t } = useTranslation();
  const {
    openDiscardModal,
    setOpenDiscardModal,
    discardAll,
    getNodeContent,
    deviceID,
  } = props;

  const handleOk = () => {
    discardAll();
  };

  const handleCancel = () => {
    setOpenDiscardModal(false);
    getNodeContent();
  };

  return (
    <Modal
      isVisible={openDiscardModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      okText={t(jsonData.Confirm)}
    >
      <Row className="device-popup-header">
        <Col className="device-popup-heading">
          <p className="discardDevice">{t(jsonData.DiscardDeviceConfiguration)}</p>
        </Col>
      </Row>
      <PendingChangesTable
        setCheckedList={[]}
        openModal={openDiscardModal}
        setOpenModal={setOpenDiscardModal}
        getNodeContent={getNodeContent}
        deviceID={deviceID}
      />
    </Modal>
  );
};
